import { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import Water1 from "../Assets/icons-03.svg";
import Waste1 from "../Assets/icons-06.svg";
import RE1 from "../Assets/icons-04.svg";
import Group1 from "../Assets/icons-02.svg";
import Energy1 from "../Assets/icons-05.svg";
import Carbon1 from "../Assets/carbon 1.svg";
import GreenBuilding from "../Assets/icons-01.svg";
import Retrofits from "../Assets/Retrofits.svg";
import InnovationRadiant from "../Assets/Innovative Radiant Cooling System.svg";
import CommandCenter from "../Assets/Infosys Central Command Center.svg";

import "./environmentalgallery.css";
import EnvironmentIcon from "../../../../common_assets/environment-icon 1.png";
import SocialIcon from "../../../../common_assets/social-icon 1.png";
import GovernanceIcon from "../../../../common_assets/governance-icon 1.png";
import ScrollContainer from "react-indiana-drag-scroll";
import ScrollTextImg from "../../../../common_assets/ScrollText.svg";
import ScrollhandImg from "../../../../common_assets/Hand_cursor.svg";

const useStyle = makeStyles({
  imageStyle: {
    width: "100%",
    height: "100%",
  },
});

let animationSpeed = 1;

function resumeAnimation() {
  setTimeout(() => {
    animationSpeed = 1;
  }, 500);
}

function StopAnimation() {
  animationSpeed = 0;
}

function InfiniteScroll() {
  var scrollPos = 10;
  var container = document.getElementsByClassName("container")[0];

  container.parentElement.scrollLeft = 1;

  container.parentElement.addEventListener(
    "scroll",
    function () {
      scrollPos =
        (container.parentElement.pageXOffset ||
          container.parentElement.scrollLeft) -
        (container.parentElement.clientLeft || 0);

      if (scrollPos >= 1395) {
        container.parentElement.scrollLeft = 1.8;
      }
      if (scrollPos <= 1) {
        container.parentElement.scrollLeft = 1395;
      }
    },
    false
  );

  function AnimateScroll() {
    container.parentElement.scrollLeft += animationSpeed;
  }
  AnimateScroll();
  setInterval(AnimateScroll, 20);
}

const EnvironmentGallery = ({ setCurrentIndex, setIsCarouselVisible }) => {
  const classes = useStyle();

  useEffect(() => {
    InfiniteScroll();
  }, []);

  // useEffect(() => {

  //   let slideDelay = 5;
  //   let slideDuration = 1;
  //   let slides = document.querySelectorAll(".Col");
  //   let numSlides = slides.length;

  //   gsap.set(slides, { xPercent: (i) => i * 150 });

  //   function autoPlay() {
  //     animation.play();
  //     gsap.fromTo(
  //       animation,
  //       { timeScale: 0 },
  //       { timeScale: 1, duration: 0.3, overwrite: true, ease: "power2.in" }
  //     );
  //   }

  //   let wrap = gsap.utils.wrap(-100, (numSlides + 3) * 100);
  //   let wrapProgress = gsap.utils.wrap(0, 1);

  //   let timer = gsap.delayedCall(slideDelay, autoPlay).pause();

  //   let proxy = document.createElement("div");
  //   let slideWidth = 0;
  //   let wrapWidth = 0;
  //   let animation = gsap.timeline({ repeat: -1 });
  //   resize();

  //   // let draggable = new Draggable(proxy, {
  //   //   trigger: ".slides-container",
  //   //   type: "x",
  //   //   inertia: true,
  //   //   onPressInit: function () {
  //   //     animation.pause();
  //   //     timer.pause();
  //   //     updateProgress();
  //   //   },
  //   //   snap: {
  //   //     x: (value) => gsap.utils.snap(slideWidth, value),
  //   //   },
  //   //   onDrag: updateProgress,
  //   //   onThrowUpdate: updateProgress,
  //   //   onThrowComplete: function () {
  //   //     timer.restart(true);
  //   //   },
  //   // });

  //   window.addEventListener("resize", resize);

  //   function animateSlides(direction) {
  //     var progress = animation.progress() + direction / numSlides;
  //     timer.pause();
  //     animation.pause();
  //     gsap.to(animation, {
  //       duration: slideDuration,
  //       progress: gsap.utils.snap(1 / numSlides, progress),
  //       overwrite: true,
  //       modifiers: {
  //         progress: wrapProgress, // value => (value < 0 || value === 1 ? 1 : 0) + (value % 1)
  //       },
  //       onComplete: () => timer.restart(true),
  //     });
  //   }

  //   function updateProgress() {
  //     animation.progress(
  //       wrapProgress(gsap.getProperty(proxy, "x") / wrapWidth)
  //     );
  //   }

  //   function resize() {
  //     var progress = animation.progress();
  //     slideWidth = slides[0].offsetWidth;
  //     wrapWidth = slideWidth * numSlides;
  //     animation
  //       .progress(0)
  //       .clear()
  //       .to(slides, {
  //         duration: 15,
  //         xPercent: "-=" + numSlides * 100,
  //         ease: "none",
  //         modifiers: {
  //           xPercent: wrap,
  //         },
  //       })
  //       .to(proxy, { x: wrapWidth, duration: 15, ease: "none" }, 0)
  //       .progress(progress);
  //   }

  //   // Hamster(document.querySelector('.slides-container')).wheel(function(event, delta, deltaX, deltaY) {
  //   //   event.preventDefault();
  //   //   animateSlides(delta/30);
  //   // });

  // }, []);

  return (
    <>
      <ScrollContainer
        className="sliderGallery"
        nativeMobileScroll={false}
        hideScrollbars={true}
      >
        <div className="container">
          <div className="Col Col1">
            <div
              className="card"
              onClick={() => {
                setCurrentIndex(0);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Group1} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingEnvironment">CLIMATE CHANGE</p>
          </div>

          <div className="Col Col3">
            <div
              className="card"
              onClick={() => {
                setCurrentIndex(1);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={RE1} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingEnvironment">RENEWABLE ENERGY</p>
          </div>

          <div className="Col Col2">
            <div
              className="card"
              onClick={() => {
                setCurrentIndex(2);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Carbon1} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingEnvironment">CARBON OFFSETS</p>
          </div>

          <div className="Col Col1">
            <div
              className="card"
              onClick={() => {
                setCurrentIndex(3);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Energy1} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingEnvironment">ENERGY EFFICIENCY</p>
          </div>

          <div className="Col Col3">
            <div
              className="card"
              onClick={() => {
                setCurrentIndex(4);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Retrofits} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingEnvironment">RETROFITS</p>
          </div>

          <div className="Col Col1">
            <div
              className="card"
              onClick={() => {
                setCurrentIndex(5);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={InnovationRadiant} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingEnvironment">
              INNOVATIVE RADIANT COOLING SYSTEM
            </p>
          </div>

          <div className="Col Col2">
            <div
              className="card"
              onClick={() => {
                setCurrentIndex(6);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={CommandCenter} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingEnvironment">
              {" "}
              INFOSYS CENTRAL COMMAND CENTER
            </p>
          </div>

          <div className="Col Col3">
            <div
              className="card"
              onClick={() => {
                setCurrentIndex(7);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Water1} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingEnvironment">WATER</p>
          </div>
          <div className="Col Col2">
            <div
              className="card"
              onClick={() => {
                setCurrentIndex(8);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Waste1} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingEnvironment">WASTE</p>
          </div>

          <div className="Col Col3">
            <div
              className="card"
              onClick={() => {
                setCurrentIndex(9);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={GreenBuilding} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingEnvironment">GREEN BUILDINGS</p>
          </div>

          <div className="Col Col1">
            <div
              className="card"
              onClick={() => {
                setCurrentIndex(0);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Group1} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingEnvironment">CLIMATE CHANGE</p>
          </div>

          <div className="Col Col3">
            <div
              className="card"
              onClick={() => {
                setCurrentIndex(1);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={RE1} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingEnvironment">RENEWABLE ENERGY</p>
          </div>

          <div className="Col Col2">
            <div
              className="card"
              onClick={() => {
                setCurrentIndex(2);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Carbon1} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingEnvironment">CARBON OFFSETS</p>
          </div>

          <div className="Col Col1">
            <div
              className="card"
              onClick={() => {
                setCurrentIndex(3);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Energy1} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingEnvironment">ENERGY EFFICIENCY</p>
          </div>

          <div className="Col Col3">
            <div
              className="card"
              onClick={() => {
                setCurrentIndex(4);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Retrofits} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingEnvironment">RETROFITS</p>
          </div>

          <div className="Col Col1">
            <div
              className="card"
              onClick={() => {
                setCurrentIndex(5);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={InnovationRadiant} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingEnvironment">
              INNOVATIVE RADIANT COOLING SYSTEM
            </p>
          </div>

          <div className="Col Col2">
            <div
              className="card"
              onClick={() => {
                setCurrentIndex(6);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={CommandCenter} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingEnvironment">
              {" "}
              INFOSYS CENTRAL COMMAND CENTER
            </p>
          </div>

          <div className="Col Col3">
            <div
              className="card"
              onClick={() => {
                setCurrentIndex(7);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Water1} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingEnvironment">WATER</p>
          </div>
          <div className="Col Col2">
            <div
              className="card"
              onClick={() => {
                setCurrentIndex(8);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Waste1} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingEnvironment">WASTE</p>
          </div>

          <div className="Col Col3">
            <div
              className="card"
              onClick={() => {
                setCurrentIndex(9);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={GreenBuilding} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingEnvironment">GREEN BUILDINGS</p>
          </div>
        </div>
      </ScrollContainer>
      <div style={{ position: "absolute", right: "45%", bottom: "60px" }}>
        <div style={{ display: "flex" }}>
          <img src={ScrollhandImg} style={{ width: "18px", padding: "3px" }} />
          <img src={ScrollTextImg} />
        </div>
      </div>
      <div style={{ right: "0px", position: "absolute", bottom: "0px" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              <img src={EnvironmentIcon} alt="Not Found" width="60%" />
            </div>
            <p
              style={{
                fontFamily: "TungstenFont",
                fontSize: "2rem",
                marginBottom: "0px",
                marginTop: "0px",
                marginLeft: "-6%",
                color: "#003726",
              }}
            >
              ENVIRONMENT
            </p>
          </div>
          <Link to="social">
            <div>
              <img src={SocialIcon} alt="Not Found" width="60%" />
            </div>
          </Link>
          <Link to="governance">
            <div>
              <img src={GovernanceIcon} alt="Not Found" width="60%" />
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default EnvironmentGallery;
