import { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import ScrollContainer from "react-indiana-drag-scroll";

import CorporateGovernance from "../Assets/Corporate Governance.svg";
import CyberSecurity from "../Assets/cyber_security.svg";
import DataPrivacy from "../Assets/data_privacy.svg";
import InformationManagement from "../Assets/Information_mangement.svg";
import Advocacy from "../Assets/Advocay.svg";
import IntegrityAndComplience from "../Assets/Integrity and compliance.svg";
import SupplyChain from "../Assets/Responsible supply chain.svg";
import Transparency from "../Assets/Transparency.svg";

import ScrollTextImg from "../../../../common_assets/ScrollText.svg";
import ScrollhandImg from "../../../../common_assets/Hand_cursor.svg";
import EnvironmentIcon from "../../../../common_assets/environment-icon 1.png";
import SocialIcon from "../../../../common_assets/social-icon 1.png";
import GovernanceIcon from "../../../../common_assets/governance-icon 1.png";
import "./governancegallery.css";

const useStyle = makeStyles({
  imageStyle: {
    width: "100%",
    height: "100%",
  },
});

let animationSpeed = 1;

function resumeAnimation() {
  setTimeout(() => {
    animationSpeed = 1;
  }, 500);
}

function StopAnimation() {
  animationSpeed = 0;
}

function InfiniteScroll() {
  var scrollPos = 10;
  var container = document.getElementsByClassName("container")[0];

  container.parentElement.scrollLeft = 1;

  container.parentElement.addEventListener(
    "scroll",
    function () {
      scrollPos =
        (container.parentElement.pageXOffset ||
          container.parentElement.scrollLeft) -
        (container.parentElement.clientLeft || 0);

      if (scrollPos >= 1395) {
        container.parentElement.scrollLeft = 1.8;
      }
      if (scrollPos <= 1) {
        container.parentElement.scrollLeft = 1395;
      }
    },
    false
  );

  function AnimateScroll() {
    container.parentElement.scrollLeft += animationSpeed;
  }
  AnimateScroll();
  setInterval(AnimateScroll, 20);
}

const GovernanceGallery = ({ setCurrentIndex, setIsCarouselVisible }) => {
  const classes = useStyle();

  useEffect(() => {
    InfiniteScroll();
  }, []);

  return (
    <>
      <ScrollContainer
        className="sliderGallery"
        nativeMobileScroll={false}
        hideScrollbars={true}
      >
        <div className="container">
          <div className="Col Col1">
            <div
              className="governancecard"
              onClick={() => {
                setCurrentIndex(0);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img
                  src={CorporateGovernance}
                  className={classes?.imageStyle}
                />
              </div>
            </div>
            <p className="CardHeadingGovernance">CORPORATE GOVERNANCE</p>
          </div>
          <div className="Col Col2">
            <div
              className="governancecard"
              onClick={() => {
                setCurrentIndex(1);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={DataPrivacy} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingGovernance">DATA PRIVACY</p>
          </div>
          <div className="Col Col3">
            <div
              className="governancecard"
              style={{ width: "13vw" }}
              onClick={() => {
                setCurrentIndex(2);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img
                  src={InformationManagement}
                  className={classes?.imageStyle}
                />
              </div>
            </div>
            <p className="CardHeadingGovernance">INFORMATION MANAGEMENT</p>
          </div>
          <div className="Col Col1">
            <div
              className="governancecard"
              style={{ width: "13vw" }}
              onClick={() => {
                setCurrentIndex(3);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={CyberSecurity} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingGovernance">CYBER SECURITY</p>
          </div>

          <div className="Col Col3">
            <div
              className="governancecard"
              onClick={() => {
                setCurrentIndex(4);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img
                  src={IntegrityAndComplience}
                  className={classes?.imageStyle}
                />
              </div>
            </div>
            <p className="CardHeadingGovernance">INTEGRITY AND COMPLIANCE</p>
          </div>

          <div className="Col Col2">
            <div
              className="governancecard"
              onClick={() => {
                setCurrentIndex(5);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Transparency} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingGovernance">TRANSPARENCY</p>
          </div>

          <div className="Col Col3">
            <div
              className="governancecard"
              onClick={() => {
                setCurrentIndex(6);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Advocacy} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingGovernance">ADVOCACY</p>
          </div>
          <div className="Col Col1">
            <div
              className="governancecard"
              onClick={() => {
                setCurrentIndex(7);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={SupplyChain} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingGovernance">RESPONSIBLE SUPPLY CHAIN</p>
          </div>

          <div className="Col Col3">
            <div
              className="governancecard"
              onClick={() => {
                setCurrentIndex(0);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img
                  src={CorporateGovernance}
                  className={classes?.imageStyle}
                />
              </div>
            </div>
            <p className="CardHeadingGovernance">CORPORATE GOVERNANCE</p>
          </div>
          <div className="Col Col2">
            <div
              className="governancecard"
              onClick={() => {
                setCurrentIndex(1);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={DataPrivacy} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingGovernance">DATA PRIVACY</p>
          </div>
          <div className="Col Col3">
            <div
              className="governancecard"
              style={{ width: "13vw" }}
              onClick={() => {
                setCurrentIndex(2);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img
                  src={InformationManagement}
                  className={classes?.imageStyle}
                />
              </div>
            </div>
            <p className="CardHeadingGovernance">INFORMATION MANAGEMENT</p>
          </div>
          <div className="Col Col1">
            <div
              className="governancecard"
              style={{ width: "13vw" }}
              onClick={() => {
                setCurrentIndex(3);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={CyberSecurity} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingGovernance">CYBER SECURITY</p>
          </div>

          <div className="Col Col3">
            <div
              className="governancecard"
              onClick={() => {
                setCurrentIndex(4);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img
                  src={IntegrityAndComplience}
                  className={classes?.imageStyle}
                />
              </div>
            </div>
            <p className="CardHeadingGovernance">INTEGRITY AND COMPLIANCE</p>
          </div>

          <div className="Col Col2">
            <div
              className="governancecard"
              onClick={() => {
                setCurrentIndex(5);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Transparency} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingGovernance">TRANSPARENCY</p>
          </div>

          <div className="Col Col1">
            <div
              className="governancecard"
              onClick={() => {
                setCurrentIndex(6);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Advocacy} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingGovernance">ADVOCACY</p>
          </div>
          <div className="Col Col2">
            <div
              className="governancecard"
              onClick={() => {
                setCurrentIndex(7);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={SupplyChain} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingGovernance">RESPONSIBLE SUPPLY CHAIN</p>
          </div>
        </div>
      </ScrollContainer>
      <div style={{ position: "absolute", right: "45%", bottom: "60px" }}>
        <div style={{ display: "flex" }}>
          <img src={ScrollhandImg} style={{ width: "18px", padding: "3px" }} />
          <img src={ScrollTextImg} />
        </div>
      </div>
      <div style={{ right: "0px", position: "absolute", bottom: "0px" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Link to="environment">
            <div>
              <img src={EnvironmentIcon} alt="Not Found" width="60%" />
            </div>
          </Link>

          <Link to="social">
            <div>
              <img src={SocialIcon} alt="Not Found" width="60%" />
            </div>
          </Link>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              <img src={GovernanceIcon} alt="Not Found" width="60%" />
            </div>
            <p
              style={{
                fontFamily: "TungstenFont",
                fontSize: "2rem",
                marginBottom: "0px",
                marginTop: "0px",
                marginLeft: "-6%",
                color: "#381460",
              }}
            >
              GOVERNANCE
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default GovernanceGallery;
