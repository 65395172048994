import React, { useEffect, useState } from "react";

import "./loader.css";

const WaveLoader = ({ loadingType }) => {
  const [fillColor, setFillColor] = useState("");

  useEffect(() => {
    if (loadingType === "environment") {
      setFillColor("#50AF91");
    } else if (loadingType === "social") {
      setFillColor("#027FBD");
    } else {
      setFillColor("#8D2A90");
    }
  }, [loadingType]);

  return (
    <div id="sea" class="sea">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern
            id="water"
            width=".25"
            height="2"
            patternContentUnits="objectBoundingBox"
          >
            <path
              fill={fillColor}
              d="M0.25,1H0c0,0,0-0.659,0-0.916c0.083-0.303,0.158,0.334,0.25,0C0.25,0.327,0.25,1,0.25,0.5z"
            />
          </pattern>
        </defs>
        <rect id="waves" class="water-fill" fill="url(#water)" width="3000" />
      </svg>
    </div>
  );
};

export default WaveLoader;
