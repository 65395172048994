import { useState, useEffect } from "react";
import GovernanceCarousel from "./GovernanceCarousal/index";
import GovernanceGallery from "./Gallery/index";

const GovernanceComp = () => {
  const [isCarouselVisible, setIsCarouselVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <>
      {isCarouselVisible ? (
        <GovernanceCarousel
          currentIndex={currentIndex}
          setCarouselVisible={setIsCarouselVisible}
        />
      ) : (
        <GovernanceGallery
          setCurrentIndex={setCurrentIndex}
          setIsCarouselVisible={setIsCarouselVisible}
        />
      )}
    </>
  );
};

export default GovernanceComp;
