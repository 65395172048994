export const journeys = [
  {
    id: 1,
    title: "FISCAL 2019",
    subheading: "CLIENTS",
    desc: "Established the Infosys Knowledge Institute (IKI), which helps industry leaders develop",
    modal: [
      {
        title: "CLIENTS",
        description:
          "Established the Infosys Knowledge Institute (IKI), which helps industry leaders develop a deeper understanding of business and technology trends through compelling thought leadership ",
      },
      {
        title: "PEOPLE",
        description:
          "Infosys’ performance management system, iCount, bagged the Association of Talent Development’s Excellence in Practice Award for talent management.. InfyMe app launched to help employees with first-hand information and access to systems and processes anytime, anywhere. InStep, our global internship program, was ranked No. 1 in the Best Overall Internship category by Vault.com’s survey for 2019",
      },
      {
        title: " ",
        description:
          "InfyTQ app launched for engineering students in India to help them become industry-ready",
      },
      {
        title: " ",
        description:
          "InfyMe app launched to help employees with first-hand information and access to systems and processes anytime, anywhere. InStep, our global internship program, was ranked No. 1 in the Best Overall Internship category by Vault.com’s survey for 2019",
      },
      {
        title: " ",
        description:
          "InStep, our global internship program, was ranked No. 1 in the Best Overall Internship category by Vault.com’s survey for 2019",
      },
      {
        title: "COMMUNITIES",
        description:
          "Infosys Foundation launched the Aarohan Social Innovation Awards",
      },
      {
        title: "ENVIRONMENT",
        description:
          "Recognized at the Global Green Future Leadership Awards for the Best Climate Change Program",
      },
      {
        title: " ",
        description:
          "Winner of the Greenbuild Leadership Award by the USGBC (category: occupant)",
      },
      {
        title: " ",
        description:
          "Took a pledge on World Environment Day to make our campuses 'non-recyclable plastic-free' by 2020",
      },
    ],
  },
  {
    id: 2,
    title: "FISCAL 2018",
    subheading: "CLIENTS",
    desc: "Infosys NIA® launched; a platform that brings machine learning together with the deep",
    modal: [
      {
        title: "CLIENTS",
        description:
          "Infosys NIA® launched; a platform that brings machine learning together with the deep knowledge of an organization, to drive automation and innovation",
      },
      {
        title: "PEOPLE",
        description:
          "Infosys Lex launched; a highly scalable and modular learning platform that allows employees to access learning content from anywhere, anytime",
      },
      {
        title: " ",
        description: "Stock incentive rewards program relaunched for employees",
      },
      {
        title: "COMMUNITIES",
        description:
          "Undertook a pioneering project to showcase precast construction technology in India",
      },
      {
        title: "ENVIRONMENT",
        description:
          "With the addition of 30MW solar PV in Sira, Karnataka, we took our total installed capacity to 46.2MW solar power",
      },
      {
        title: " ",
        description:
          "Collaborated with Leibniz University, Germany to conceptualize and implement a fully-automated solar heat assisted dryer for treating sewage sludge",
      },
      {
        title: " ",
        description:
          "European Patent granted for radiant cooling solution developed by Infosys",
      },

      {
        title: "SUSTAINABILITY LEADERSHIP",
        description:
          "Inducted into Dow Jones Sustainability Indices – DJSI World and DJSI Emerging Markets, while also making it into the CDP ‘A’ list",
      },
    ],
  },
  {
    id: 3,
    title: "FISCAL 2017",
    subheading: "COMMUNITIES",
    desc: "Infosys Foundation USA launches the InfyMaker Awards program to to further its commitment to the",
    modal: [
      {
        title: "COMMUNITIES",
        description:
          "Infosys Foundation USA launches the InfyMaker Awards program to further its commitment to the spirit of ‘making’ in everyday learning and to celebrate creative excellence in makers of all ages across the US",
      },
      {
        title: "PEOPLE",
        description: "3rd party safety audit of all our campuses in India",
      },
      {
        title: "ENVIRONMENT",
        description:
          "Received Microsoft Supplier Program Climate Change Leadership award instituted by Microsoft in collaboration with the US Environmental Protection",
      },
      {
        title: " ",
        description:
          "Agency (EPA) and mirrors the requirements of the EPA’s Climate Leadership Awards",
      },
      {
        title: " ",
        description:
          "Joined Carbon Pricing Leadership Coalition (CPLC) and announced an internal carbon price in January; the internal carbon price is fixed at US$10.5 per ton of CO2e",
      },
      {
        title: " ",
        description:
          "Achieved our goal of 50% reduction in per capita electricity since we started our journey in 200",
      },
      {
        title: " ",
        description:
          "Infosys Pune Phase-2 campus became the largest campus in the world to be awarded LEED Platinum certification (category: Existing Buildings)",
      },
      {
        title: " ",
        description:
          "Implemented 24 KW solar grid in Leh-Ladakh region, as part of a rural electrification project to light up 20 villages",
      },
    ],
  },
  {
    id: 4,
    title: "FISCAL 2016",
    subheading: "COMMUNITIES",
    desc: "US$250 million Innovate in India Fund announced to support Indian start-ups",
    modal: [
      {
        title: "PEOPLE",
        description: "3rd party safety audit of all our campuses in India",
      },
      {
        title: "COMMUNITIES",
        description:
          "US$250 million Innovate in India Fund announced to support Indian start-ups",
      },
      {
        title: "ENVIRONMENT",
        description:
          "Carbon offset projects worth 63 crore introduced, a step towards achieving the stated carbon neutrality goal.",
      },
      {
        title: " ",
        description:
          "Initiated three community-based carbon offset projects in rural India: ",
      },
      {
        title: " ",
        description:
          "7,620 household biogas units construction in Ramanagara, Karnataka 21,500 efficient cookstoves distribution in Raichur, Karnataka",
      },
      {
        title: " ",
        description:
          "15,400 improved cookstoves distribution in Udaipur, Rajasthan ",
      },
      {
        title: " ",
        description:
          "6.6 MW solar PV farm installed in our Pocharam campus at Hyderabad",
      },
      {
        title: " ",
        description:
          "Total built-up area of green certified buildings recorded at 3.8 million sq ft, with 14 LEED and four GRIHA certified building",
      },
    ],
  },
  {
    id: 5,
    title: "FISCAL 2015",
    subheading: "ENVIRONMENT",
    desc: "Winner of the International Ashden Award (Green Oscars) for Sustainable Buildings",
    modal: [
      {
        title: "ENVIRONMENT",
        description:
          "Winner of the International Ashden Award (Green Oscars) for Sustainable Buildings",
      },
      {
        title: " ",
        description:
          "Energy Efficiency in Buildings (EEB) 2.0 guide launched in partnership with the World Business Council for Sustainable Development (WBCSD)",
      },
      {
        title: " ",
        description:
          "First Indian company to join RE100, a global platform for major companies committed to 100% renewable power",
      },

      {
        title: "ETHICS AND CORPORATE GOVERNANCE",
        description:
          "EdgeVerve Systems Limited, a new subsidiary, created to develop and sell our products, platforms and solutions; Finacle ® merged into EdgeVerve Systems Limited in 2015 to consolidate the latter’s product offerings",
      },
      {
        title: " ",
        description:
          "State-of-the-art command center launched at our Bengaluru campus, heralding an industry revolution in large-scale operations management",
      },
    ],
  },
  {
    id: 6,
    title: "FISCAL 2014",
    subheading: "COMMUNITIES",
    desc: "Ian Kiernan Award for Corporate Social Responsibility 2013 received from the Australian",
    modal: [
      {
        title: "COMMUNITIES",
        description:
          "Ian Kiernan Award for Corporate Social Responsibility 2013 received from the Australian Human Resources Institute for our inclusive and meaningful CSR actions in Australia",
      },
      {
        title: "ENVIRONMENT",
        description:
          "By implementing one of the world’s largest retrofit programs across our India campuses, we achieved a 10.1 MW reduction in connected load",
      },
      {
        title: " ",
        description:
          "Air conditioning retrofits alone reduced the connected load by 4.8 MW",
      },
      {
        title: " ",
        description:
          "1st ever building with radiant panels in India inaugurated at our Bengaluru campus ",
      },
      {
        title: " ",
        description:
          "288,065 trees on campus, almost double the number we had in 2009",
      },
      {
        title: " ",
        description:
          "Infosys case study showcased at the WBCSD conference in Montreux",
      },
      {
        title: "ETHICS AND CORPORATE GOVERNANCE",
        description:
          "1st IT company globally to publish a GRI G4 comprehensive report",
      },
    ],
  },
  {
    id: 7,
    title: "FISCAL 2013",
    subheading: "ENVIRONMENT",
    desc: "One of the top 25 performers in the ‘Caring for Climate’ initiative by the UN Global Compact",
    modal: [
      {
        title: "ENVIRONMENT",
        description:
          "One of the top 25 performers in the ‘Caring for Climate’ initiative by the UN Global Compact and the UN Environment Program",
      },
      {
        title: " ",
        description:
          "World’s 8th greenest company in a ranking published by Newsweek  ",
      },
      {
        title: " ",
        description:
          "Partnerships with research institutes and leading corporates for ‘building efficiency’ innovations ",
      },

      {
        title: "ETHICS AND CORPORATE GOVERNANCE",
        description:
          "Among Forbes magazine’s World’s Most Innovative Companies ",
      },
      {
        title: " ",
        description:
          "One of the first Indian companies to publish the Business Responsibility Report",
      },
    ],
  },
  {
    id: 8,
    title: "FISCAL 2012",
    subheading: "ENVIRONMENT",
    desc: "Implemented radiant cooling technology for the first time in a commercial building in India",
    modal: [
      {
        title: "ENVIRONMENT",
        description:
          "Implemented radiant cooling technology for the first time in a commercial building in India ",
      },
      {
        title: " ",
        description:
          "1 million sq ft of LEED Platinum certified buildings across Infosys campuses ",
      },
      {
        title: " ",
        description:
          "Solar PV plants installed with 250 kW capacity at Jaipur and 125 kW capacity at Trivandrum capacity",
      },
      {
        title: " ",
        description:
          "Won the Bry-Air HVAC Award for innovative systems design at Hyderabad campus",
      },
      {
        title: " ",
        description:
          "Best in industry in water management at the World Water Summit",
      },
      {
        title: "ETHICS AND CORPORATE GOVERNANCE",
        description:
          " Zurich-headquartered Lodestone Holding AG acquired as a subsidiary; this added 750+ experienced consultants and 200 clients in different industries and expands Infosys’ presence in Europe",
      },
    ],
  },
  {
    id: 9,
    title: "FISCAL 2011",
    subheading: "COMMUNITIES",
    desc: "21,000 houses constructed in nine months for the victims of the floors in North Karnataka",
    modal: [
      {
        title: "COMMUNITIES",
        description:
          "21,000 houses constructed in nine months for the victims of the floors in North Karnataka, with the help of a grant from the Infosys Foundation ",
      },
      {
        title: " ",
        description:
          "Infosys Rural BPO Program set up to provide attractive career options to educated rural youth at Pulla village, Andhra Pradesh and at Kaup village in Udupi, Karnataka",
      },
      {
        title: "ENVIRONMENT",
        description:
          "Won the CII National Award for excellence in Energy Management",
      },
      {
        title: " ",
        description: "Committed to Carbon Neutrality Goals in the UN ",
      },
      {
        title: " ",
        description:
          "Employee volunteer-led Eco Clubs formed at various development centers",
      },
    ],
  },
];
