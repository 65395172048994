import React from "react";
import { makeStyles } from "@material-ui/core";
import Timeline from "../Timeline/index";
import ScrollTextImg from "../../common_assets/ScrollText.svg";
import ScrollhandImg from "../../common_assets/Hand_cursor.svg";
import leftBottomImg from "../../common_assets/left-bottom-img.svg";
const useStyles = makeStyles({
  mainHeadingBox: {
    margin: "auto",
    height: "78vh",
    // marginTop: "7%",
  },
  mainHeading: {
    fontFamily: "TungstenFont",
    fontSize: "5em",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "70px",
    lineHeight: "69px",
    textAlign: "center",
    marginTop: "0%",
    marginBottom: "0",
  },
  subHeadingBox: {
    marginLeft: "22%",
    marginRight: "22%",
  },
  subHeading: {
    textAlign: "center",
    fontSize: "20px",
    fontFamily: "RegularFont",
    color: "#003726",
    marginTop: "12px",
  },
  scrollHandSec: {
    display: "flex",
    justifyContent: "center",
  },
  scrollHandSecDiv: {
    display: "flex",
  },
});

const CarbonNeutral = () => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.mainHeadingBox}>
        <h1 className={classes.mainHeading}>2020: Infosys is Carbon Neutral</h1>
        <div className={classes.subHeadingBox}>
          <p className={classes.subHeading} style={{ marginBottom: "0" }}>
            Infosys has become carbon neutral for fy 2030, 30 years ahead of the
            timeline set by the Paris Agreement. In 2019, Infosys received the
            prestigious United Nations Global Climate Action Award in the
            'Climate Neutral Now' category.
          </p>
        </div>
        <div style={{ paddingBottom: "2%" }}>
          <Timeline />
        </div>
        <div className={classes.scrollHandSec}>
          <div className={classes.scrollHandSecDiv}>
            <img
              src={ScrollhandImg}
              style={{ width: "18px", padding: "3px" }}
            />
            <img src={ScrollTextImg} />
          </div>
        </div>
        <div class="left-bottom-img">
          <img
            style={{ width: "75%", verticalAlign: "bottom" }}
            src={leftBottomImg}
          />
        </div>
      </div>
    </div>
  );
};

export default CarbonNeutral;
