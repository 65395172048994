import { useState, useEffect } from "react";
import PrioritySection from "./index";

import { useHistory } from "react-router";

const ParentComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingType, setLoadingType] = useState("environment");

  const history = useHistory();

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);

      setTimeout(() => {
        if (isLoading) {
          if (loadingType === "environment") {
            history.push("pillars/environment");
          } else if (loadingType === "social") {
            history.push("pillars/social");
          } else {
            history.push("pillars/governance");
          }
        }
      }, 2000);
    }
  }, [isLoading]);

  return (
    <>
      <PrioritySection
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        loadingType={loadingType}
        setLoadingType={setLoadingType}
      />
    </>
  );
};

export default ParentComponent;
