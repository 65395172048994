import JourneySoFar from "../components/JourneyPageSoFar/JourneySoFar";

function JourneyPage() {
  return (
    <div>
      <JourneySoFar />
    </div>
  );
}

export default JourneyPage;
