import SocialGallery from "../components/PrioritySection/SocialComponent";

function OurPrioritySocial() {
  return (
    <div>
      <SocialGallery />
    </div>
  );
}

export default OurPrioritySocial;
