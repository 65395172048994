import GovernanceGallery from "../components/PrioritySection/GovernanceGallery/index";

function OurPriorityGovernance() {
  return (
    <div>
      <GovernanceGallery />
    </div>
  );
}

export default OurPriorityGovernance;
