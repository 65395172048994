import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CorporateGovernance from "../Assets/Corporate Governance.svg";
import CyberSecurity from "../Assets/cyber_security.svg";
import DataPrivacy from "../Assets/data_privacy.svg";
import InformationManagement from "../Assets/Information_mangement.svg";

import Advocacy from "../Assets/Advocay.svg";
import IntegrityAndComplience from "../Assets/Integrity and compliance.svg";
import SupplyChain from "../Assets/Responsible supply chain.svg";
import Transparency from "../Assets/Transparency.svg";

import CloseIcon from "../../../../common_assets/close_btn.png";
import LeftArrow from "../../../../common_assets/left_arrow.png";
import RightArrow from "../../../../common_assets/right_arrow.png";
import "./governanceCarousal.css";

const GovernanceCarousel = ({ currentIndex, setCarouselVisible }) => {
  return (
    <div className="parent-of-carousal">
      <Carousel
        selectedItem={currentIndex}
        autoPlay={false}
        renderArrowPrev={(onClickHandler, hasPrev) =>
          hasPrev && (
            <button
              type="button"
              className="left-arrow"
              onClick={onClickHandler}
            >
              <img src={LeftArrow} width="10%" />
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext) =>
          hasNext && (
            <button
              type="button"
              className="right-arrow"
              onClick={onClickHandler}
            >
              <img src={RightArrow} width="10%" />
            </button>
          )
        }
      >
        {/* // first Card */}

        <div className="parent-wrapper">
          <div className="parent-comp-1">
            <p className="gov-main-heading">CORPORATE GOVERNANCE</p>
            <div className="gov-main-card">
              <div className="upper-content-div ">
                <p className="gov-main-content">
                  First company to introduce concept of ESOPs in India
                  <br />
                  <br />
                  First Indian company to be listed on the NASDAQ stock exchange
                  <br />
                  <br />
                  First Indian company to facilitate American Depository
                  Receipts (ADR) participation in Indian share buyback
                  <br />
                  <br />
                  First Indian company to sign a unilateral Advance Pricing
                  Agreement (APA) with US Internal Revenue Service (IRS)
                  <br />
                  <br />
                  First Indian company to institute performance-based stock
                  incentives under the expanded stock ownership program aligned
                  to TSR
                </p>
              </div>
              <div style={{ padding: "1%", overflow: "hidden" }}>
                <img
                  src={CorporateGovernance}
                  alt="Not Found"
                  style={{ width: "36%" }}
                />
              </div>
            </div>
            <div className="card-side-bar">
              <div
                onClick={() => setCarouselVisible(false)}
                className="close-button-box"
              >
                <img src={CloseIcon} className="close-button" />
              </div>

              <div className="right-info">
                <p className="gov-card-type">GOVERNANCE</p>
                <div style={{ display: "flex" }}>
                  <p className="gov-para-style cardno-style">01</p>
                  <p className="gov-para-style total-no-style">/08</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* // second card */}
        <div className="parent-wrapper">
          <div className="parent-comp-1">
            <p className="gov-main-heading">DATA PRIVACY</p>
            <div className="gov-main-card">
              <div className="upper-content-div ">
                <p className="gov-main-content">
                  Infosys is among the first few organizations globally, to have
                  its framework certified with accreditation, for the recently
                  released ISO 27701 privacy information management standard
                  <br />
                  <br />
                  Member of IAPP advisory board
                  <br />
                  <br />
                  Designated expert in the Data Privacy working group of ISO’s
                  SC27 committee
                  <br />
                  <br />
                  Infosys was a key contributor in the development of India’s
                  Data Privacy standard IS 17428, published in December 2020 by
                  BIS (Bureau of Indian Standards)
                </p>
              </div>
              <div style={{ padding: "1%", overflow: "hidden" }}>
                <img
                  src={DataPrivacy}
                  alt="Not Found"
                  style={{ width: "36%" }}
                />
              </div>
            </div>
            <div className="card-side-bar">
              <div
                onClick={() => setCarouselVisible(false)}
                className="close-button-box"
              >
                <img src={CloseIcon} className="close-button" />
              </div>

              <div className="right-info">
                <p className="gov-card-type">GOVERNANCE</p>
                <div style={{ display: "flex" }}>
                  <p className="gov-para-style cardno-style">02</p>
                  <p className="gov-para-style total-no-style">/08</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* // Third Card */}
        <div className="parent-wrapper">
          <div className="parent-comp-2">
            <p className="gov-main-heading">INFORMATION MANAGEMENT</p>
            <div className="gov-main-card">
              <div className="upper-content-div ">
                <p className="gov-main-content">
                  We have a global network of seven Cyber Defense Centers spread
                  across EMEA, US and APAC, and a strong set of innovation hubs
                  and integrated platforms.
                  <br />
                  <br />
                  We have 4,500+ cybersecurity professionals and seven Cyber
                  Defense Centers across India, the US and Europe, to provide
                  actionable threat intelligence and insights.
                </p>
              </div>
              <div style={{ padding: "2%", overflow: "hidden" }}>
                <img
                  src={InformationManagement}
                  alt="Not Found"
                  style={{ width: "44%" }}
                />
              </div>
            </div>
            <div className="card-side-bar">
              <div
                onClick={() => setCarouselVisible(false)}
                className="close-button-box"
              >
                <img src={CloseIcon} className="close-button" />
              </div>

              <div className="right-info">
                <p className="gov-card-type">GOVERNANCE</p>
                <div style={{ display: "flex" }}>
                  <p className="gov-para-style cardno-style">03</p>
                  <p className="gov-para-style total-no-style">/08</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* //forth card */}
        <div className="parent-wrapper">
          <div className="parent-comp-1">
            <p className="gov-main-heading">CYBER SECURITY</p>
            <div className="gov-main-card">
              <div className="upper-content-div ">
                <p className="gov-main-content">
                  Cybersecurity has been an important issue for corporations of
                  all sizes due to the ever-growing number of cybersecurity
                  breaches, resulting in financial as well as data loss, and
                  reputational damage.
                  <br />
                  <br />
                  We are committed to protect the confidentiality, availability,
                  and integrity of information assets from internal and external
                  threats.
                </p>
              </div>
              <div style={{ padding: "2%", overflow: "hidden" }}>
                <img
                  src={CyberSecurity}
                  alt="Not Found"
                  style={{ width: "36%" }}
                />
              </div>
            </div>
            <div className="card-side-bar">
              <div
                onClick={() => setCarouselVisible(false)}
                className="close-button-box"
              >
                <img src={CloseIcon} className="close-button" />
              </div>

              <div className="right-info">
                <p className="gov-card-type">GOVERNANCE</p>
                <div style={{ display: "flex" }}>
                  <p className="gov-para-style cardno-style">04</p>
                  <p className="gov-para-style total-no-style">/08</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="parent-wrapper">
          <div className="parent-comp-2">
            <p className="gov-main-heading">INTEGRITY AND COMPLIANCE</p>
            <div className="gov-main-card">
              <div className="upper-content-div ">
                <p className="gov-main-content">
                  A well-articulated Code of Conduct and Ethics ensures the
                  participation of all stakeholders in building an ethical
                  organization.
                  <br />
                  <br />A strong Whistleblower Policy, coupled with robust
                  non-retaliation measures, inspires stakeholder trust.
                  <br />
                  <br />
                  An extensive Compliance and Integrity Plan (CIP) allows us to
                  proactively monitor 2,500+ laws and regulations that apply to
                  our operations across 67 countries and translate them into
                  30,000+ compliance actions.
                </p>
              </div>
              <div style={{ padding: "2%", overflow: "hidden" }}>
                <img
                  src={IntegrityAndComplience}
                  alt="Not Found"
                  style={{ width: "43%" }}
                />
              </div>
            </div>
            <div className="card-side-bar">
              <div
                onClick={() => setCarouselVisible(false)}
                className="close-button-box"
              >
                <img src={CloseIcon} className="close-button" />
              </div>

              <div className="right-info">
                <p className="gov-card-type">GOVERNANCE</p>
                <div style={{ display: "flex" }}>
                  <p className="gov-para-style cardno-style">05</p>
                  <p className="gov-para-style total-no-style">/08</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="parent-wrapper">
          <div className="parent-comp-1">
            <p className="gov-main-heading">TRANSPARENCY</p>
            <div className="gov-main-card">
              <div
                style={{
                  paddingTop: "7%",
                  paddingLeft: "3%",
                  paddingRight: "3%",
                  paddingBottom: "2%",
                }}
              >
                <p className="gov-main-content" style={{ lineHeight: "14px" }}>
                  13 years of sustainability reporting in accordance with GRI.
                  <br />
                  <br />
                  In 1996 First Indian company to voluntarily adopt US and six
                  other countries’ GAAP reporting
                  <br />
                  <br />
                  Listed as an index component of the DJSI World indices for the
                  4<sup>th</sup> year in a row.
                  <br />
                  <br />
                  Recognized by CDP (formerly the Carbon Disclosure Project) for
                  leadership on climate action for the 5<sup>th</sup> year in a
                  row
                  <br />
                  <br />
                  Received Gold recognition from EcoVadis for CSR practices,
                  including environment, labor and human rights, ethics and
                  sustainable procurement
                  <br />
                  <br />
                  No. 1 in CRISIL ESG rating
                  <br />
                  <br />
                  Confirmed as an FTSE4Good Index Series constituent
                  <br />
                  <br />
                  Received the highest Corporate Governance Rating (CGR) from
                  ICRA.
                </p>
              </div>
              <div style={{ padding: "1%", overflow: "hidden" }}>
                <img
                  src={Transparency}
                  alt="Not Found"
                  style={{ width: "27%" }}
                />
              </div>
            </div>
            <div className="card-side-bar">
              <div
                onClick={() => setCarouselVisible(false)}
                className="close-button-box"
              >
                <img src={CloseIcon} className="close-button" />
              </div>

              <div className="right-info">
                <p className="gov-card-type">GOVERNANCE</p>
                <div style={{ display: "flex" }}>
                  <p className="gov-para-style cardno-style">06</p>
                  <p className="gov-para-style total-no-style">/08</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="parent-wrapper">
          <div className="parent-comp-1">
            <p className="gov-main-heading">ADVOCACY</p>
            <div className="gov-main-card">
              <div className="upper-content-div ">
                <p className="gov-main-content">
                  Members of UN GISD (Global investors for sustainable
                  development)
                  <br />
                  <br />
                  Members of WEF IBC
                  <br />
                  <br />
                  UNGC signatories
                  <br />
                  <br />
                  Infosys will power The Economist Group’s latest global
                  initiative to advance the discourse on sustainability amongst
                  world leaders and businesses
                </p>
              </div>
              <div style={{ padding: "2%", overflow: "hidden" }}>
                <img src={Advocacy} alt="Not Found" style={{ width: "43%" }} />
              </div>
            </div>
            <div className="card-side-bar">
              <div
                onClick={() => setCarouselVisible(false)}
                className="close-button-box"
              >
                <img src={CloseIcon} className="close-button" />
              </div>

              <div className="right-info">
                <p className="gov-card-type">GOVERNANCE</p>
                <div style={{ display: "flex" }}>
                  <p className="gov-para-style cardno-style">07</p>
                  <p className="gov-para-style total-no-style">/08</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* // Fifth Card */}

        {/* Sixth Card */}
        <div className="parent-wrapper">
          <div className="parent-comp-2">
            <p className="gov-main-heading">RESPONSIBLE SUPPLY CHAIN</p>
            <div className="gov-main-card">
              <div
                style={{
                  paddingTop: "8%",
                  paddingLeft: "4%",
                  paddingRight: "4%",
                  paddingBottom: "1%",
                }}
              >
                <p className="gov-main-content" style={{ lineHeight: "14px" }}>
                  In order to strengthen responsible business practices across
                  our supply chain, we have implemented the VIVA framework.
                  <br />
                  <br />
                  VISIONING-Drafting policies and guidelines and operating code
                  for the supply chain
                  <br />
                  <br />
                  INCULCATING-Focusing on process improvements
                  <br />
                  <br /> VALUING-Creating awareness and providing training and
                  competency-building
                  <br />
                  <br />
                  ASSESSING-Assessing supplier performance, along with
                  environmental, social and governance parameters.
                  <br />
                  <br />
                  Our Supplier Code of Conduct enables us in managing and
                  addressing concerns on sustainable business practices within
                  our supply chains and enhances the effectiveness of their
                  efforts.
                </p>
              </div>
              <div style={{ padding: "1%", overflow: "hidden" }}>
                <img
                  src={SupplyChain}
                  alt="Not Found"
                  style={{ width: "34%" }}
                />
              </div>
            </div>
            <div className="card-side-bar">
              <div
                onClick={() => setCarouselVisible(false)}
                className="close-button-box"
              >
                <img src={CloseIcon} className="close-button" />
              </div>

              <div className="right-info">
                <p className="gov-card-type">GOVERNANCE</p>
                <div style={{ display: "flex" }}>
                  <p className="gov-para-style cardno-style">08</p>
                  <p className="gov-para-style total-no-style">/08</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default GovernanceCarousel;
