import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Water1 from "../Assets/icons-03.svg";
import Waste1 from "../Assets/icons-06.svg";
import RE1 from "../Assets/mountain-01.svg";
import Group1 from "../Assets/icons-02.svg";
import Energy1 from "../Assets/icons-05.svg";
import Carbon1 from "../Assets/carbon 1.svg";
import Building1 from "../Assets/icons-01.svg";
import CloseIcon from "../../../../common_assets/close_btn.png";
import LeftArrow from "../../../../common_assets/left_arrow.png";
import RightArrow from "../../../../common_assets/right_arrow.png";
import Retrofits from "../Assets/Retrofits.svg";
import InnovationRadiant from "../Assets/Innovative Radiant Cooling System.svg";
import CommandCenter from "../Assets/Infosys Central Command Center.svg";

import "./carousalStyle.css";

const EnvironmentCarousel = ({ currentIndex, setCarouselVisible }) => {
  useEffect(() => {
    console.log(currentIndex);
  }, [currentIndex]);

  return (
    <div className="parent-of-carousal">
      <Carousel
        selectedItem={currentIndex}
        autoPlay={false}
        renderArrowPrev={(onClickHandler, hasPrev) =>
          hasPrev && (
            <button
              type="button"
              className="left-arrow"
              onClick={onClickHandler}
            >
              <img src={LeftArrow} width="10%" />
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext) =>
          hasNext && (
            <button
              type="button"
              className="right-arrow"
              onClick={onClickHandler}
            >
              <img src={RightArrow} width="10%" />
            </button>
          )
        }
      >
        {/* // first Card */}

        <div className="parent-wrapper">
          <div className="parent-comp-1">
            <p className="main-heading">CLIMATE CHANGE</p>
            <div className="main-card">
              <div className="upper-content-div">
                <p className="main-content" style={{ lineHeight: "15px" }}>
                  Infosys began its climate action journey in 2008 and in 2011,
                  long before the Paris Agreement was born, made a voluntary
                  commitment to become carbon neutral.
                  <br />
                  <br />
                  Infosys achieved Carbon Neutrality in fiscal 2020, 30 years
                  ahead of the 2050 timeline set by the Paris Agreement and
                  continued to be carbon neutral in 2021.
                  <br />
                  <br />
                  Infosys was the first Company in India to certify its carbon
                  neutrality against PAS 2060:2014, the highest standard for
                  carbon neutral certification worldwide.
                  <br />
                  <br />
                  Infosys has committed to science-based targets initiatives
                  (SBTi) to align with the Paris agreement’s goal of keeping
                  temperatures well below 2 degree C scenario.
                </p>
              </div>
              <div style={{ padding: "1%", overflow: "hidden" }}>
                <img src={Group1} alt="Not Found" style={{ width: "28%" }} />
              </div>
            </div>
            <div className="card-side-bar-2">
              <div
                onClick={() => setCarouselVisible(false)}
                className="close-button-box"
              >
                <img src={CloseIcon} className="close-button" />
              </div>

              <div className="right-info">
                <p className="card-type">ENVIRONMENT</p>
                <div style={{ display: "flex" }}>
                  <p className="para-style cardno-style">01</p>
                  <p className="para-style total-no-style">/10</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="parent-wrapper">
          <div className="parent-comp-1">
            <p className="main-heading">RENEWABLE ENERGY</p>
            <div className="main-card">
              <div className="upper-content-div">
                <p className="main-content">
                  Infosys is the first Indian signatory to the RE100 global
                  campaign.
                  <br />
                  <br />
                  We have 60 MW of installed solar PV capacity.
                  <br />
                  <br />
                  50% of our electricity for our India operations were sourced
                  from renewable sources.
                  <br />
                  <br />
                  In-house solar PV lab set-up to test various PV under single
                  weather conditions.
                </p>
              </div>
              <div style={{ overflow: "hidden" }}>
                <img
                  src={RE1}
                  alt="Not Found"
                  style={{ width: "63%", marginLeft: "-37%", marginTop: "-3%" }}
                />
              </div>
            </div>
            <div className="card-side-bar-1">
              <div
                onClick={() => setCarouselVisible(false)}
                className="close-button-box"
              >
                <img src={CloseIcon} className="close-button" />
              </div>

              <div className="right-info">
                <p className="card-type">ENVIRONMENT</p>
                <div style={{ display: "flex" }}>
                  <p className="para-style cardno-style">02</p>
                  <p className="para-style total-no-style">/10</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="parent-wrapper">
          <div className="parent-comp-1">
            <p className="main-heading">CARBON OFFSETS</p>
            <div className="main-card">
              <div className="upper-content-div">
                <p className="main-content">
                  Infosys’ community-based carbon offset program fulfills the
                  twin objectives of climate action and creating a positive
                  impact on rural communities.
                  <br />
                  <br />
                  Our project portfolio includes 9 carbon offset projects.
                  <br />
                  <br />
                  11 of 17 SDGs impacted through our carbon offset projects
                  <br />
                  <br />
                  119,000 families reached through carbon offset projects, while
                  creating over 2,600 jobs
                </p>
              </div>
              <div style={{ padding: "1%", overflow: "hidden" }}>
                <img
                  src={Carbon1}
                  width="200px"
                  alt="Not Found"
                  style={{ width: "28%" }}
                />
              </div>
            </div>
            <div className="card-side-bar-1">
              <div
                onClick={() => setCarouselVisible(false)}
                className="close-button-box"
              >
                <img src={CloseIcon} className="close-button" />
              </div>

              <div className="right-info">
                <p className="card-type">ENVIRONMENT</p>
                <div style={{ display: "flex" }}>
                  <p className="para-style cardno-style">03</p>
                  <p className="para-style total-no-style">/10</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="parent-wrapper">
          <div className="parent-comp-1">
            <p className="main-heading">ENERGY EFFICIENCY</p>
            <div className="main-card">
              <div className="upper-content-div">
                <p className="main-content">
                  Efficient design of new buildings, deep green retrofits in
                  existing buildings and operational excellence through smart
                  automation were crucial in achieving energy efficiency.
                  <br />
                  <br />
                  We implemented over 15 energy efficiency projects across HVAC
                  and lighting spaces.
                  <br />
                  <br />
                  Smart automation has enabled remote monitoring, control and
                  optimization of building operations across over 30 million
                  sq.ft. of space.
                  <br />
                  <br />
                  We have achieved 55% reduction in per capita electricity
                  consumption compared to FY 2008 baseline.
                  <br />
                  <br />
                  Infosys buildings are known to be among the most efficient
                  globally and are a benchmark in the industry.
                </p>
              </div>
              <div style={{ padding: "1%", overflow: "hidden" }}>
                <img src={Energy1} alt="Not Found" style={{ width: "24%" }} />
              </div>
            </div>
            <div className="card-side-bar-1">
              <div
                onClick={() => setCarouselVisible(false)}
                className="close-button-box"
              >
                <img src={CloseIcon} className="close-button" />
              </div>

              <div className="right-info">
                <p className="card-type">ENVIRONMENT</p>
                <div style={{ display: "flex" }}>
                  <p className="para-style cardno-style">04</p>
                  <p className="para-style total-no-style">/10</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="parent-wrapper">
          <div className="parent-comp-1">
            <p className="main-heading">RETROFITS</p>
            <div className="main-card">
              <div className="upper-content-div" style={{ paddingTop: "8%" }}>
                <p className="main-content">
                  Infosys has invested in retrofits – that is, re-engineering
                  and retrofitting the existing systems with new,
                  energy-efficient ones
                  <br />
                  <br />
                  Air-conditioning retrofits have helped us achieve a total
                  reduction of 17 MW in connected load
                  <br />
                  <br />
                  Lighting retrofits alone have helped us achieve a total
                  reduction of 2.3 MW in connected load
                  <br />
                  <br />
                  We have observed a 25% reduction in external heat gain and
                  substantial reduction in our annual energy consumption after
                  this retrofit.
                  <br />
                  <br />
                  UPS retrofits have helped us achieve a total reduction of 14
                  MW in connected load.
                  <br />
                  <br />
                  Retrofits have helped us improve thermal as well as visual
                  comfort for our building occupants, having a positive
                  influence on their productivity at work.
                </p>
              </div>
              <div style={{ padding: "1%", overflow: "hidden" }}>
                <img src={Retrofits} alt="Not Found" style={{ width: "24%" }} />
              </div>
            </div>
            <div className="card-side-bar-1">
              <div
                onClick={() => setCarouselVisible(false)}
                className="close-button-box"
              >
                <img src={CloseIcon} className="close-button" />
              </div>

              <div className="right-info">
                <p className="card-type">ENVIRONMENT</p>
                <div style={{ display: "flex" }}>
                  <p className="para-style cardno-style">05</p>
                  <p className="para-style total-no-style">/10</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="parent-wrapper">
          <div className="parent-comp-2">
            <p className="main-heading">INNOVATIVE RADIANT COOLING SYSTEM</p>
            <div className="main-card">
              <div className="upper-content-div">
                <p className="main-content">
                  Developed by Infosys in-house team
                  <br />
                  <br />
                  Received European patent in 2018
                  <br />
                  <br />
                  Produces twice the capacity and costs half of current
                  solutions in the market
                  <br />
                  <br />
                  Produces 150 W/m2 at 8 K delta T as per EN14240 standards
                  <br />
                  <br />
                  Only part of the ceiling needs with Radiflux panels and very
                  easy to install and leak proof fittings
                  <br />
                  <br />2 million sq.ft of office space at Infosys with radiant
                  baffles
                </p>
              </div>
              <div style={{ padding: "1%", overflow: "hidden" }}>
                <img
                  src={InnovationRadiant}
                  alt="Not Found"
                  style={{ width: "29%" }}
                />
              </div>
            </div>
            <div className="card-side-bar-1">
              <div
                onClick={() => setCarouselVisible(false)}
                className="close-button-box"
              >
                <img src={CloseIcon} className="close-button" />
              </div>

              <div className="right-info">
                <p className="card-type">ENVIRONMENT</p>
                <div style={{ display: "flex" }}>
                  <p className="para-style cardno-style">06</p>
                  <p className="para-style total-no-style">/10</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="parent-wrapper">
          <div className="parent-comp-2">
            <p className="main-heading">INFOSYS CENTRAL COMMAND CENTER</p>
            <div className="main-card">
              <div className="upper-content-div">
                <p className="main-content">
                  Infosys has set up central command central at Bengaluru
                  <br />
                  <br />
                  It helps to optimize various operations across 30 million
                  sq.ft from single location
                  <br />
                  <br />
                  Enables continuous monitoring and verification of operations
                  in real-time.
                  <br />
                  <br />
                  Apply automated energy saving algorithms to conserve energy
                  <br />
                  <br />
                  Provides operational data to better design for the future.
                  <br />
                  <br />
                  Enables the proactive management of our Key Performance
                  Indicators (KPIs)
                  <br />
                  <br />
                  Initiated a cultural change in the way we manage resources at
                  Infosys
                </p>
              </div>
              <div style={{ padding: "1%", overflow: "hidden" }}>
                <img
                  src={CommandCenter}
                  alt="Not Found"
                  style={{ width: "24%" }}
                />
              </div>
            </div>
            <div className="card-side-bar-1">
              <div
                onClick={() => setCarouselVisible(false)}
                className="close-button-box"
              >
                <img src={CloseIcon} className="close-button" />
              </div>

              <div className="right-info">
                <p className="card-type">ENVIRONMENT</p>
                <div style={{ display: "flex" }}>
                  <p className="para-style cardno-style">07</p>
                  <p className="para-style total-no-style">/10</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="parent-wrapper">
          <div className="parent-comp-1">
            <p className="main-heading">WATER</p>
            <div className="main-card">
              <div className="upper-content-div">
                <p className="main-content">
                  Water being a scarce and invaluable natural resource, we are
                  strongly committed to its conservation through the 3R (Reduce,
                  Reuse, Recycle) approach.
                  <br />
                  <br />
                  35 lakes/ponds with a holding capacity of 330 million litres
                  for rainwater harvesting, along with 370 injection wells have
                  been built across our India
                  <br />
                  <br />
                  More than 64% reduction in per capita water consumption
                  compared to FY 2008 baseline
                  <br />
                  <br />
                  100% wastewater generated at campuses is recycled.
                </p>
              </div>
              <div style={{ padding: "2%", overflow: "hidden" }}>
                <img
                  src={Water1}
                  width="200px"
                  alt="Not Found"
                  style={{ maxHeight: "269px", maxWidth: "200px" }}
                />
              </div>
            </div>
            <div className="card-side-bar-1">
              <div
                onClick={() => setCarouselVisible(false)}
                className="close-button-box"
              >
                <img src={CloseIcon} className="close-button" />
              </div>

              <div className="right-info">
                <p className="card-type">ENVIRONMENT</p>
                <div style={{ display: "flex" }}>
                  <p className="para-style cardno-style">08</p>
                  <p className="para-style total-no-style">/10</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* // second card */}

        {/* // Third Card */}

        {/* //forth card */}

        {/* // Fifth Card */}

        {/* Sixth Card */}
        <div className="parent-wrapper">
          <div className="parent-comp-1">
            <p className="main-heading">WASTE</p>
            <div className="main-card">
              <div className="upper-content-div">
                <p className="main-content">
                  We seek to uphold our ambition of zero waste to landfills
                  through active minimization combined with technology
                  investment in recycling and streamlining systems and
                  processes.
                  <br />
                  <br />
                  17 biogas and composting plants established within Indian
                  campuses
                  <br />
                  <br />
                  We have installed capacity to treat 100% of organic waste
                  within our campuses.
                  <br />
                  <br />
                  We achieved 91% reduction in non-recyclable and single-use
                  plastic
                  <br />
                  <br />
                  We have installed automated solar-assisted sludge dryers at 5
                  of our campuses to treat 3,500 tons of sewage sludge per
                  annum.
                </p>
              </div>
              <div style={{ padding: "2%", overflow: "hidden" }}>
                <img
                  src={Waste1}
                  width="200px"
                  alt="Not Found"
                  style={{ maxHeight: "269px", maxWidth: "200px" }}
                />
              </div>
            </div>
            <div className="card-side-bar-1">
              <div
                onClick={() => setCarouselVisible(false)}
                className="close-button-box"
              >
                <img src={CloseIcon} className="close-button" />
              </div>

              <div className="right-info">
                <p className="card-type">ENVIRONMENT</p>
                <div style={{ display: "flex" }}>
                  <p className="para-style cardno-style">09</p>
                  <p className="para-style total-no-style">/10</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Seventh Card */}
        <div className="parent-wrapper">
          <div className="parent-comp-1">
            <p className="main-heading">GREEN BUILDINGS</p>
            <div className="main-card">
              <div className="upper-content-div">
                <p className="main-content">
                  All our buildings, new and old, follow the highest standards
                  of sustainability so as to minimize our impact on the
                  environment, while focusing on employee health and
                  productivity.
                  <br />
                  <br />
                  In fiscal 2021, ~1 mn sq.ft of our new buildings were awarded
                  the highest green building certification.
                  <br />
                  <br />
                  26 mn Sq.ft of our workspace accorded the highest level of
                  green building certification.
                </p>
              </div>
              <div style={{ padding: "1%", overflow: "hidden" }}>
                <img src={Building1} alt="Not Found" style={{ width: "40%" }} />
              </div>
            </div>
            <div className="card-side-bar-1">
              <div
                onClick={() => setCarouselVisible(false)}
                className="close-button-box"
              >
                <img src={CloseIcon} className="close-button" />
              </div>

              <div className="right-info">
                <p className="card-type">ENVIRONMENT</p>
                <div style={{ display: "flex" }}>
                  <p className="para-style cardno-style">10</p>
                  <p className="para-style total-no-style">/10</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default EnvironmentCarousel;
