import ParentComponent from "../components/PrioritySection/parentComp";

function OurPriority() {
  return (
    <div>
      <ParentComponent />
    </div>
  );
}

export default OurPriority;
