import { useState, useEffect } from "react";
import SocialCarousel from "./SocialCarousal/index";
import SocialGallery from "./Gallery/index";

const SocialComp = () => {
  const [isCarouselVisible, setIsCarouselVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);


  useEffect(() => {
    console.log(currentIndex)
  }, [currentIndex])
  return (
    <>
      {isCarouselVisible ? (
        <SocialCarousel
          currentIndex={currentIndex}
          setCarouselVisible={setIsCarouselVisible}
        />
      ) : (
        <SocialGallery
          setCurrentIndex={setCurrentIndex}
          setIsCarouselVisible={setIsCarouselVisible}
        />
      )}
    </>
  );
};

export default SocialComp;
