import "./App.css";
import Navbar from "./components/Navbar/navbar";
import { BrowserRouter as Router, Link, Switch, Route } from "react-router-dom";
import HomePage from "./Pages/homepage";
import OurPriority from "./Pages/ourPriority";
import OurPriorityEnvironment from "./Pages/OurPriorityEnvironment";
import OurPrioritySocial from "./Pages/OurPrioritySocial";
import OurPriorityGovernance from "./Pages/OurPriorityGovernance";
import JourneyPageSoFar from "./Pages/JourneyPageSoFar";
import RotateImg from "./common_assets/rotate_device.jpg";
import CarbonNeutral from "./components/JourneyToCarbon/carbonNeutral";

function App() {
  return (
    <Router>
      <div>
        <div className="landScapeMode">
          <img src={RotateImg} />
        </div>
        <Switch>
          <Route exact path="/pillars">
            <div className="PageBackground">
              <header className="App-header">
                <Navbar />
              </header>
              <OurPriority />
            </div>
          </Route>
          <Route path="/pillars/environment">
            <div className="Environment">
              <header className="App-header">
                <Navbar />
              </header>
              <OurPriorityEnvironment />
            </div>
          </Route>
          <Route path="/pillars/social">
            <div className="Social">
              <header className="App-header">
                <Navbar />
              </header>
              <OurPrioritySocial />
            </div>
          </Route>
          <Route path="/pillars/governance">
            <div className="Governance">
              <header className="App-header">
                <Navbar />
              </header>
              <OurPriorityGovernance />
            </div>
          </Route>
          <Route exact path="/journey">
            <div className="PageBackground">
              <header className="App-header">
                <Navbar />
              </header>
              <JourneyPageSoFar />
            </div>
          </Route>
          <Route exact path="/">
            <div className="PageBackground">
              <header className="App-header">
                <Navbar />
              </header>
              <HomePage />
            </div>
          </Route>
          <Route exact path="/carbon-neutral">
            <div className="PageBackground">
              <header className="App-header">
                <Navbar />
              </header>
              <CarbonNeutral />
            </div>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
