import styled from "styled-components";

export const ImgCard1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #b2d6cc;
  height: 52vh;
  cursor: pointer;
`;

export const ImgCard2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #a0cffb;
  height: 52vh;
  cursor: pointer;
`;

export const ImgCard3 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ccb7d3;
  height: 52vh;
  cursor: pointer;
  @media only screen and (max-width: 660px) {
    height: 330px;
  }
  @media only screen and (max-width: 390px) {
    height: 270px;
  }
`;

export const MainWrapper = styled.div`
  margin-left: 70px;
  margin-right: 70px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 860px) {
    margin-left: 30px;
    flex-direction: column;
    align-items: center;
  }
`;

export const Heading1 = styled.div`
  color: #003726;
  font-size: 2em;
  font-weight: bold;
  @media only screen and (max-width: 860px) {
    font-size: 1.6em;
  }
  @media only screen and (max-width: 450px) {
    font-size: 1.3em;
  }
  @media only screen and (max-width: 290px) {
    font-size: 0.9em;
  }
`;

export const Paragraph1 = styled.div`
   color: #003726,
   fontSize: 20px,
  @media only screen and (max-width: 860px): {
    font-size: 1.4em
  }
  @media only screen and (max-width: 370px): {
    font-size: 0.9em
  }
  @media only screen and (max-width: 290px) : {
    font-size: 0.6em
  },
`;

export const Heading2 = styled.div`
   color: #001738,
   font-size: 2em,
   font-weight: bold,
   @media only screen and (max-width: 860px) {
    font-size: 1.3em;
  }
`;

export const Heading3 = styled.div`
  color: #381460;
  font-size: 2em;
  font-weight: bold;
  @media only screen and (max-width: 860px) {
    font-size: 1.5em;
  }
`;

export const MainCard = styled.div`
  max-width: 30%;
  @media only screen and (max-width: 860px) {
    margin-top: 20px;
    max-width: 45%;
  }
  @media only screen and (max-width: 596px) {
    margin-top: 20px;
    max-width: 55%;
  }
  @media only screen and (max-width: 450px) {
    margin-top: 20px;
    max-width: 65%;
  }
  @media only screen and (max-width: 370px) {
    margin-top: 20px;
    max-width: 80%;
  }
  @media only screen and (max-width: 290px) {
    margin-top: 20px;
    max-width: 90%;
  }
`;

export const HeadingBox = styled.div`
  text-align: -webkit-left;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 10px;
  margin-bottom: 0px;
  @media only screen and (max-width: 860px) {
    padding-top: 10px;
  }
`;
