import React from "react";
import { Modal, makeStyles } from "@material-ui/core";
import "./journeyPage.css";
import CloseIcon from "../../common_assets/close_btn.png";

const ReadMoreModal = ({ openModal, setOpenModal, entryData }) => {
  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      width="100%"
      outline="none"
      BackdropProps={{
        style: { backgroundColor: "rgba(201, 201, 201, 0.93)" },
      }}
    >
      <div className="modal-content-wrapper-1">
        <div className="modal-content-wrapper-2">
          <div className="modal-content-wrapper-3">
            <p className="modal-main-heading">{entryData?.title}</p>
            <div className="modal-inner-content ">
              <div>
                {entryData?.modal?.map((i) => (
                  <div>
                    <h3 className="heading-text">{i.title}</h3>
                    <p className="description-text">{i.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div onClick={() => setOpenModal(false)} className="close-button">
          <img style={{ maxWidth: "90%" }} src={CloseIcon} />
        </div>
      </div>
    </Modal>
  );
};

export default ReadMoreModal;
