import { useState, useEffect } from "react";
import EnvironmentCarousel from "./EnvironmentCarousal/index";
import EnvironmentGallery from "./Gallery";

const EnvironmentComp = () => {
  const [isCarouselVisible, setIsCarouselVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);


  useEffect(() => {
    console.log(currentIndex)
  }, [currentIndex])
  return (
    <>
      {isCarouselVisible ? (
        <EnvironmentCarousel
          currentIndex={currentIndex}
          setCarouselVisible={setIsCarouselVisible}
        />
      ) : (
        <EnvironmentGallery
          setCurrentIndex={setCurrentIndex}
          setIsCarouselVisible={setIsCarouselVisible}
        />
      )}
    </>
  );
};

export default EnvironmentComp;
