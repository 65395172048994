import react from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import "./timelines.scss";
import startImg from "../../common_assets/start.svg";

const Timeline = () => {
  return (
    <>
      <ScrollContainer nativeMobileScroll={false} hideScrollbars={true}>
        <section class="ps-timeline-sec">
          <div class="timeline-container">
            <ol class="ps-timeline">
              <li class="list-first-item">
                <div>
                  <div class="img-handler-top">
                    <h1>2008</h1>
                  </div>
                  <div class="ps-bot">
                    <p>1st carbon footprint reporting</p>
                  </div>
                  <span class="ps-sp-top">
                    <img class="start-img" src={startImg} />
                  </span>
                </div>
              </li>
              <li>
                <div class="img-handler-top">
                  <h1>2009</h1>
                </div>
                <div class="ps-bot">
                  <p>Eco-clubs formed to engage employees on sustainability</p>
                </div>
                <span class="ps-sp-top">
                  <div class="checkmark">L</div>
                </span>
              </li>

              <li>
                <div class="img-handler-top">
                  <h1>2010</h1>
                </div>
                <div class="ps-bot">
                  <p>
                    Initiated large scale water saving efforts across campuses
                  </p>
                </div>
                <span class="ps-sp-top">
                  <div class="checkmark">L</div>
                </span>
              </li>
              <li>
                <div class="img-handler-top">
                  <h1>2011</h1>
                </div>
                <div class="ps-bot">
                  <p>Carbon Neutral commitment at UN</p>
                </div>
                <span class="ps-sp-top">
                  <div class="checkmark">L</div>
                </span>
              </li>
              <li>
                <div class="img-handler-top">
                  <h1>2012</h1>
                </div>
                <div class="ps-bot">
                  <p>
                    1st commercial building in India with Radiant cooling
                    technology
                  </p>
                </div>
                <span class="ps-sp-top">
                  <div class="checkmark">L</div>
                </span>
              </li>
              <li>
                <div class="img-handler-top">
                  <h1>2013</h1>
                </div>
                <div class="ps-bot">
                  <p>1st carbon footprint reporting</p>
                </div>
                <span class="ps-sp-top">
                  <div class="checkmark">L</div>
                </span>
              </li>
              <li>
                <div class="img-handler-top">
                  <h1>2014</h1>
                </div>
                <div class="ps-bot">
                  <p>International Ashden award for sustainable buildings</p>
                </div>
                <span class="ps-sp-top">
                  <div class="checkmark">L</div>
                </span>
              </li>
              <li>
                <div class="img-handler-top">
                  <h1>2015</h1>
                </div>
                <div class="ps-bot">
                  <p>
                    Among top 25 in the ‘Caring for Climate’ initiative by
                    UNGC/UNEP
                  </p>
                </div>
                <span class="ps-sp-top">
                  <div class="checkmark">L</div>
                </span>
              </li>
              <li>
                <div class="img-handler-top">
                  <h1>2016</h1>
                </div>
                <div class="ps-bot">
                  <p>6.6 MW Solar farm in Infosys Hyderabad Campus</p>
                </div>
                <span class="ps-sp-top">
                  <div class="checkmark">L</div>
                </span>
              </li>
              <li>
                <div class="img-handler-top">
                  <h1>2017</h1>
                </div>
                <div class="ps-bot">
                  <p>Announced the internal carbon price</p>
                </div>
                <span class="ps-sp-top">
                  <div class="checkmark">L</div>
                </span>
              </li>
              <li>
                <div class="img-handler-top">
                  <h1>2018</h1>
                </div>
                <div class="ps-bot">
                  <p>
                    European patent for Infosys developed radiant cooling system
                  </p>
                </div>
                <span class="ps-sp-top">
                  <div class="checkmark">L</div>
                </span>
              </li>
              <li>
                <div class="img-handler-top">
                  <h1>2019</h1>
                </div>
                <div class="ps-bot">
                  <p>'UN Global Climate Action' Award</p>
                </div>
                <span class="ps-sp-top">
                  <div class="checkmark">L</div>
                </span>
              </li>
              <li class="list-last-item">
                <div class="img-handler-top">
                  <h1>2020</h1>
                </div>
                <div class="ps-bot">
                  <p>Infosys is Carbon neutral</p>
                </div>
                <span class="ps-sp-top neutral-item">
                  {" "}
                  <div class="cent-pct">100%</div>{" "}
                </span>
                <div class="hide-right-strip"></div>
              </li>
            </ol>
          </div>
        </section>
      </ScrollContainer>
    </>
  );
};

export default Timeline;
