import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../common_assets/logo/logo_black.svg";
import PDFIcon from "../../common_assets/PDF.svg";
import {
  NavWrapper,
  NavLogo,
  NavContent,
  NavLinkIcon,
  MiddleGap,
  MobileMenu,
} from "./navbarStyle";
import { Container, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  mobileMenu: {
    display: "none",
    ["@media only screen and (max-width: 598px)"]: {
      display: "flex",
      width: "100%",
      height: "calc(100vh - 70px)",
      padding: "27px 0 40px",
      opacity: "0",
      visibility: "hidden",
      position: "absolute",
      top: "82px",
      flexDirection: "column",
      backgroundColor: "#ffffff",
      transition: "all 0.3s ease",
      color: "#000",
      ["&.active"]: {
        opacity: "1",
        visibility: "visible",
        boxShadow: "0 3px 12px",
      },
      [".container"]: {
        width: "100%",
        height: "100%",
        display: " flex",
        flexDirection: "column",
        justifyContent: "space-between",
      },
      "&ul": {
        ["li"]: {
          ["a"]: {
            display: "block",
            padding: "13px 0",
            borderRadius: "5px",
            transition: "all 0.3s ease",
            color: " #02073E",
          },
          ["&:hover"]: {
            ["a"]: {
              padding: "13px 15px",
              color: "#fff",
            },
          },
        },
      },
    },
  },

  mobileMenuActive: {
    display: "none",
    ["@media only screen and (max-width: 598px)"]: {
      display: "flex",
      width: "100%",
      height: "calc(100vh - 70px)",
      padding: "27px 0 40px",
      position: "absolute",
      top: "82px",
      flexDirection: "column",
      backgroundColor: "#ffffff",
      transition: "all 0.3s ease",
      color: "#000",
      opacity: "1",
      visibility: "visible",
      boxShadow: "0 3px 12px",
      zIndex: "1",
    },
  },

  listItems: {
    paddingBottom: "20px",
    "& li": {
      display: "block",
      padding: "13px 0",
      borderRadius: "5px",
      transition: "all 0.3s ease",
      color: " #02073E",
    },
  },
  navMenu: {
    display: "none",
    ["@media only screen and (max-width: 598px)"]: {
      display: "block",
    },
  },
  navLinks: {
    fontSize: "11px",
    fontFamily: "BoldFont",
    fontWeight: "bold",
    color: "black",
    letterSpacing: "1px",
    marginBottom: "5px",
    textDecoration: "none",
    "&:hover": {
      color: "#000000;",
    },
  },
  activeNavLink: {
    paddingBottom: "2px",
    borderBottom: "2px solid black",
  },
  navContent: {
    flex: "2",
    display: "flex",
    flexDirection: "row",
    color: "#000000",
    alignItems: "flex-end",
    justifyContent: "space-evenly",
    ["@media only screen and (max-width: 598px)"]: {
      display: "none",
    },
  },
  logo: {
    width: "120px",
    ["@media only screen and (max-width: 450px)"]: {
      width: "90px",
    },
  },
  esgReport: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
});

const Navbar = () => {
  const classes = useStyles();
  const [mobileMenu, setMobileMenu] = useState(false);

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  const handleMenuClose = () => {
    setMobileMenu(false);
  };

  const navs = [
    {
      id: 1,
      label: "",
    },
    {
      id: 2,
      label: "ESG PillARS",
    },

    {
      id: 3,
      label: "ESG JOURNEY",
    },

    {
      id: 4,
      label: "ESG REPORT",
    },
  ];

  return (
    <NavWrapper>
      <NavLogo>
        <Link to="/">
          <img src={logo} alt="Not found" className={classes?.logo} />
        </Link>
      </NavLogo>
      <MiddleGap></MiddleGap>
      <div className={classes.navContent}>
        <p className={classes?.navLinks}>
          <NavLink
            to="/pillars"
            className={classes?.navLinks}
            activeClassName={classes?.activeNavLink}
          >
            ESG PILLARS
          </NavLink>
        </p>

        <p className={classes?.navLinks}>
          <NavLink
            to="/carbon-neutral"
            className={classes?.navLinks}
            activeClassName={classes?.activeNavLink}
          >
            JOURNEY TO CARBON NEUTRALITY
          </NavLink>
        </p>

        <p className={classes?.navLinks}>
          <NavLink
            to="/journey"
            className={classes?.navLinks}
            activeClassName={classes?.activeNavLink}
          >
            ESG JOURNEY
          </NavLink>
        </p>
        <div className={classes?.esgReport}>
          <p className={classes?.navLinks}>
            <Link
              to={{
                pathname:
                  "https://www.infosys.com/sustainability/documents/infosys-esg-report-2020-21.pdf",
              }}
              target="_blank"
              className={classes?.navLinks}
            >
              ESG REPORT
            </Link>
          </p>
          <NavLinkIcon>
            <img src={PDFIcon} alt="Not Found" />
          </NavLinkIcon>
        </div>
      </div>
      <div className={classes.navMenu}>
        {mobileMenu ? (
          <IconButton onClick={handleMobileMenu}>
            <CloseIcon />
          </IconButton>
        ) : (
          <IconButton onClick={handleMobileMenu}>
            <MenuIcon />
          </IconButton>
        )}
      </div>
      {/* Phone View */}
      <div
        className={mobileMenu ? classes.mobileMenuActive : classes.mobileMenu}
      >
        <Container>
          <ul className={classes.listItems}>
            <li onClick={handleMenuClose} className={classes.navLinks}>
              <NavLink
                to="/pillars"
                className={classes?.navLinks}
                activeClassName={classes?.activeNavLink}
              >
                ESG PILLARS
              </NavLink>
            </li>
            <li onClick={handleMenuClose} className={classes.navLinks}>
              <NavLink
                to="/journey"
                className={classes?.navLinks}
                activeClassName={classes?.activeNavLink}
              >
                JOURNEY TO CARBON NEUTRALITY
              </NavLink>
            </li>
            <li onClick={handleMenuClose} className={classes.navLinks}>
              <NavLink
                to="/journey"
                className={classes?.navLinks}
                activeClassName={classes?.activeNavLink}
              >
                ESG JOURNEY
              </NavLink>
            </li>
            <li onClick={handleMenuClose} className={classes.navLinks}>
              <div className={classes.esgReport}>
                <p className={classes.navLinks}>
                  {" "}
                  <Link
                    to={{
                      pathname:
                        "https://www.infosys.com/sustainability/documents/infosys-esg-report-2020-21.pdf",
                    }}
                    target="_blank"
                    className={classes?.navLinks}
                  >
                    ESG REPORT
                  </Link>
                </p>
                <NavLinkIcon>
                  <img src={PDFIcon} alt="Not Found" />
                </NavLinkIcon>
              </div>
            </li>
          </ul>
        </Container>
      </div>
    </NavWrapper>
  );
};

export default Navbar;
