import EnvironmentGallery from "../components/PrioritySection/EnvironmentalGallery";

function OurPriorityEnvironment() {
  return (
    <div>
      <EnvironmentGallery />
    </div>
  );
}

export default OurPriorityEnvironment;
