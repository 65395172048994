import styled from "styled-components";

export const NavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 5%;
`;

export const NavLogo = styled.div`
  flex: 1;
  text-align: left;
`;

export const NavContent = styled.div`
  flex: 2;
  display: flex;
  flex-direction: row;
  color: black;
  align-items: flex-end;
  justify-content: space-evenly;
`;

export const MiddleGap = styled.div`
  flex: 2;
  @media only screen and (max-width: 990px) {
    flex: 1;
  }
  @media only screen and (max-width: 990px) {
    display: none;
  }
`;

export const NavLinks = styled.p`
  font-size: 11px;
  font-family: BoldFont;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 5px;
`;

export const NavLinkIcon = styled.div`
  margin-top: 2px;
  margin-left: 5px;
`;

export const MobileMenu = styled.div`
  display: none;
  @media only screen and (max-width: 991px) {
    display: flex;
    width: 100%;
    height: calc(100vh - 70px);
    padding: 27px 0 40px;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 82px;
    flex-direction: column;
    background-color: "ffffff;
    transition: all 0.3s ease;
    color:  #000
    &.active {
      opacity: 1;
      visibility: visible;
      box-shadow: 0 3px 12px
       
    }
    .container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    ul {
      padding-bottom: 20px;
      li {
        a {
          display: block;
          padding: 13px 0;
          border-radius: 5px;
          transition: all 0.3s ease;
          color: #02073E;
        }
        &:hover {
          a {
            padding: 13px 15px;
            color: #fff;
          }
        }
      }
    }
    .reusecore__button {
      width: 100%;
      border-radius: 4px;
      background-image: -moz-linear-gradient(
        -31deg,
        rgb(64, 219, 216) 0%,
        rgb(44, 31, 132) 100%
      );
      background-image: -webkit-linear-gradient(
        -31deg,
        rgb(64, 219, 216) 0%,
        rgb(44, 31, 132) 100%
      );
      background-image: -ms-linear-gradient(
        -31deg,
        rgb(64, 219, 216) 0%,
        rgb(44, 31, 132) 100%
      );
      @media only screen and (max-width: 480px) {
        margin-top: 20px;
      }
    }
  }
`;
