import ".././App.css";

import MainComponent from "../components/MainComponent/middleComponent";

function HomePage() {
  return (
    <div>
      <MainComponent />
    </div>
  );
}

export default HomePage;
