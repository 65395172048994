import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SideNavbar from "../Navbar/sideNavbar";
import Image from "../Image/index";
import IntroImg from "../../common_assets/project-11.png";
import ArrowImg from "../../common_assets/Arrow 1.svg";
import PlayImg from "../../common_assets/play.svg";
import Footer from "../../components/Footer/index";
import buttonImg from "../../common_assets/video_1.png";
import EnvironmentImg1 from "../../common_assets/environment-icon 1.svg";
import GovernanceImg1 from "../../common_assets/governance-icon 1.svg";
import SocialImg1 from "../../common_assets/social-icon 1.svg";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import VideoModal from "./VideoModal";
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt";
import rightArrow from "../../common_assets/rightArrow.png";

const useStyle = makeStyles({
  contained: {
    backgroundColor: "transparent",
    border: "1px solid black",
    borderRadius: "0px",
    width: "35%",
  },
  imgFirst: {
    cursor: "pointer",
    height: "82vh",
    position: "relative",
    top: "0",
    left: "0",
    ["@media only screen and (max-width: 1240px)"]: {
      height: "78vh",
    },
    ["@media only screen and (max-width: 1060px)"]: {
      height: "78vh",
    },
  },
  imgSecond: {
    cursor: "pointer",
    position: "absolute",
    top: "39%",
    left: "46%",
  },
  mainHeading: {
    fontSize: "5em",
    // width: '1000px',
    textAlign: "-webkit-left",
    fontWeight: "bolder",
    lineHeight: "4.2rem",
    marginBottom: "20px",
    marginLeft: "15%",
    paddingLeft: "5px",
    paddingTop: "0px",
    fontFamily: "TungstenFont",
    overflow: "hidden",
    width: "100%",
    ["@media only screen and (max-width: 1260px)"]: {
      marginLeft: "3%",
    },
    ["@media only screen and (max-width: 1060px)"]: {
      marginLeft: "0%",
    },
    ["@media only screen and (max-width: 1023px)"]: {
      fontSize: "4.5em",
    },
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    paddingLeft: "3%",
    marginLeft: "14%",
    ["@media only screen and (max-width: 1260px)"]: {
      marginLeft: "7%",
    },
    ["@media only screen and (max-width: 1060px)"]: {
      marginLeft: "0%",
    },
  },
  regularHeading: {
    fontSize: "16px",
    fontFamily: "BoldFont",
  },
  paraContent: {
    fontFamily: "RegularFont",
  },
  videoImgComp: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  videoImgChild: {
    position: "relative",
    left: "0px",
    top: "0",
    height: "82.6vh",
  },
  heading1: {
    color: "#003726",
    fontFamily: "SemiBoldFont",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "15px",
    marginBottom: "0px",
    marginTop: "0px",
    textAlign: "left",
    ["@media only screen and (max-width: 860px)"]: {
      fontSize: "1.6em",
    },
    ["@media only screen and (max-width: 450px)"]: {
      fontSize: "1.3em",
    },
    ["@media only screen and (max-width: 290px)"]: {
      fontSize: "0.9em",
    },
  },
  paragraph1: {
    color: "#003726",
    fontFamily: "RegularFont",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "13px",
    textAlign: "left",
    marginTop: "1px",
    marginBottom: "1px",
    lineHeight: "14px",
    ["@media only screen and (max-width: 860px)"]: {
      fontSize: "16px",
    },
    ["@media only screen and (max-width: 390px)"]: {
      fontSize: "13px",
    },
    ["@media only screen and (max-width: 290px)"]: {
      fontSize: "12px",
    },
  },
  heading2: {
    color: "#001738",
    fontFamily: "SemiBoldFont",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "15px",
    marginBottom: "0px",
    marginTop: "0px",
    textAlign: "left",
    ["@media only screen and (max-width: 860px)"]: {
      fontSize: "1.6em",
    },
    ["@media only screen and (max-width: 450px)"]: {
      fontSize: "1.3em",
    },
    ["@media only screen and (max-width: 290px)"]: {
      fontSize: "0.9em",
    },
  },
  paragraph2: {
    color: "#001738",
    fontFamily: "RegularFont",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "13px",
    textAlign: "left",
    marginTop: "1px",
    marginBottom: "1px",
    lineHeight: "14px",
    ["@media only screen and (max-width: 860px)"]: {
      fontSize: "16px",
    },
    ["@media only screen and (max-width: 390px)"]: {
      fontSize: "13px",
    },
    ["@media only screen and (max-width: 280px)"]: {
      fontSize: "12px",
    },
  },
  heading3: {
    color: "#381460",
    fontFamily: "SemiBoldFont",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "15px",
    marginBottom: "0px",
    marginTop: "0px",
    textAlign: "left",
    ["@media only screen and (max-width: 860px)"]: {
      fontSize: "1.6em",
    },
    ["@media only screen and (max-width: 450px)"]: {
      fontSize: "1.3em",
    },
    ["@media only screen and (max-width: 290px)"]: {
      fontSize: "0.9em",
    },
  },
  paragraph3: {
    color: "#381460",
    fontFamily: "RegularFont",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "13px",
    textAlign: "left",
    marginTop: "1px",
    marginBottom: "1px",
    lineHeight: "14px",
    ["@media only screen and (max-width: 860px)"]: {
      fontSize: "16px",
    },
    ["@media only screen and (max-width: 390px)"]: {
      fontSize: "13px",
    },
    ["@media only screen and (max-width: 290px)"]: {
      fontSize: "12px",
    },
  },
  viewMore1: {
    fontSize: "13px",
    fontFamily: "SemiBoldFont",
    color: "#003726",
    marginTop: "0px",
    marginBottom: "0px",
    textAlign: "left",
    width: "108px",
    background: "#B2D6CD",
    borderRadius: "10px",
    textDecoration: "none",
    padding: "2px 8px",
    fontWeight: "normal",
  },
  viewMore2: {
    fontSize: "13px",
    fontFamily: "SemiBoldFont",
    color: "#001738",
    marginTop: "0px",
    marginBottom: "0px",
    textAlign: "left",
    width: "108px",
    background: "#C4E4F7",
    borderRadius: "10px",
    textDecoration: "none",
    padding: "2px 8px",
    fontWeight: "normal",
  },
  viewMore3: {
    fontSize: "13px",
    fontFamily: "SemiBoldFont",
    color: "#381460",
    marginTop: "0px",
    marginBottom: "0px",
    textAlign: "left",
    width: "108px",
    background: "#CCB7D3",
    borderRadius: "10px",
    textDecoration: "none",
    padding: "2px 8px",
    fontWeight: "normal",
  },
  linkStyle: {
    textDecoration: "none",
    background: "inherit",
  },
  flexCard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: "2%",
    height: "70px",
  },
  footer: {
    position: "absolute",
    right: "-22.5%",
    bottom: "-10px",
    ["@media only screen and (max-width: 749px)"]: {
      right: "-21%",
    },
    ["@media only screen and (max-width: 449px)"]: {
      right: "-22%",
    },
    ["@media only screen and (max-width: 320px)"]: {
      right: "-30%",
    },
  },
  arrowImg: {
    height: "12px",
    width: "28px",
    marginTop: "2px",
  },
  pillarDesc: {
    maxWidth: "65%",
    marginLeft: "3%",
  },
});
const MiddleComponent = () => {
  const classes = useStyle();
  const [openVideoModal, setOpenVideoModal] = useState(false);
  return (
    <div>
      <Grid container>
        <Grid sm={1} md={1} lg={1}></Grid>
        <Grid sm={5} xs={10} md={5} lg={5}>
          <div className={classes?.mainHeading}>
            SHAPING & SHARING <br /> AN EQUITABLE AND <br /> SUSTAINABLE DIGITAL{" "}
            <br /> FUTURE
          </div>
          <div className={classes?.contentWrapper}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className={classes?.flexCard}>
                <img src={EnvironmentImg1} width="14%" />
                <div className={classes.pillarDesc}>
                  <h4 className={classes?.heading1}>Environment</h4>
                  <p className={classes?.paragraph1}>
                    Serve the preservation of our planet by shaping and sharing
                    our technology solutions
                  </p>
                  <Link to="/pillars/environment" className={classes.linkStyle}>
                    {" "}
                    <p className={classes?.viewMore1}>
                      View More{" "}
                      <img
                        src={rightArrow}
                        className={classes?.arrowImg}
                        alt=""
                      />
                    </p>{" "}
                  </Link>
                </div>
              </div>
              <div className={classes?.flexCard}>
                <img src={SocialImg1} width="14%" />
                <div className={classes.pillarDesc}>
                  <h4 className={classes?.heading2}>Social</h4>
                  <p className={classes?.paragraph2}>
                    Serve the development of people by shaping a future with
                    meaningful opportunities for all
                  </p>
                  <Link to="/pillars/social" className={classes.linkStyle}>
                    {" "}
                    <p className={classes?.viewMore2}>
                      View More{" "}
                      <img
                        src={rightArrow}
                        className={classes?.arrowImg}
                        alt=""
                      />
                    </p>{" "}
                  </Link>
                </div>
              </div>
              <div className={classes?.flexCard}>
                <img src={GovernanceImg1} width="14%" />
                <div className={classes.pillarDesc}>
                  <h4 className={classes?.heading3}>Governance</h4>
                  <p className={classes?.paragraph3}>
                    Serve the interests of all our stakeholders by leading
                    through our core values
                  </p>
                  <Link to="/pillars/governance" className={classes.linkStyle}>
                    {" "}
                    <p className={classes?.viewMore3}>
                      View More{" "}
                      <img
                        src={rightArrow}
                        className={classes?.arrowImg}
                        alt=""
                      />
                    </p>{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid xs={10} sm={5} md={5} lg={5}>
          <div className={classes?.videoImgComp}>
            <div className={classes?.videoImgChild}>
              <img
                src={IntroImg}
                class={classes.imgFirst}
                alt="Not Found"
                onClick={() => setOpenVideoModal(true)}
              />
              <img
                src={PlayImg}
                width="11%"
                height="11%"
                class={classes.imgSecond}
                alt="Not Found"
                onClick={() => setOpenVideoModal(true)}
              />
            </div>
          </div>
        </Grid>
        <Grid sm={1} md={2} lg={2}></Grid>
        <VideoModal
          openModal={openVideoModal}
          setOpenModal={setOpenVideoModal}
        />
      </Grid>
    </div>
  );
};
export default MiddleComponent;
