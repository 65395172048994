import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import DigitalTalent from "../Assets/icons-10.svg";
import Innovation from "../Assets/icons-08.svg";
import Tech from "../Assets/icons-11.svg";
import Wellness from "../Assets/icons-14.svg";
import Achievements from "../Assets/Achievements.svg";
import EnergisingLocalCommunity from "../Assets/icons-13.svg";
import Diversity from "../Assets/icons-12.svg";

import CloseIcon from "../../../../common_assets/close_btn.png";
import LeftArrow from "../../../../common_assets/left_arrow.png";
import RightArrow from "../../../../common_assets/right_arrow.png";

import "./socialCarousal.css";

const SocialCarousel = ({ currentIndex, setCarouselVisible }) => {
  return (
    <div className="parent-of-carousal">
      <Carousel
        selectedItem={currentIndex}
        autoPlay={false}
        renderArrowPrev={(onClickHandler, hasPrev) =>
          hasPrev && (
            <button
              type="button"
              className="left-arrow"
              onClick={onClickHandler}
            >
              <img src={LeftArrow} width="10%" />
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext) =>
          hasNext && (
            <button
              type="button"
              className="right-arrow"
              onClick={onClickHandler}
            >
              <img src={RightArrow} width="10%" />
            </button>
          )
        }
      >
        {/* // first Card */}
        <div className="parent-wrapper">
          <div className="parent-comp-1">
            <p className="social-main-heading">COMMUNITY</p>
            <div className="social-main-card">
              <div className="upper-content-div-1">
                <p className="social-main-content">
                  The Infosys Foundation and Infosys Foundation USA spend over
                  US$50 million each year on impactful projects focused on
                  education, healthcare, rural development, destitute care and
                  art & culture, and support the government in disaster relief
                  programs.
                  <br />
                  <br />
                  The Infosys science foundation -74 scientists honoured till
                  date with the Infosys Prize, each carrying a gold medal, a
                  citation and a purse of $100K.
                  <br />
                  <br />
                  Our employees volunteers have always demonstrated their
                  passion to give back to the community that they are a part of
                  and support those in need in every way possible. Our employees
                  are at the forefront of our programs, whether providing relief
                  in the aftermath of natural disasters or facilitating
                  education for underprivileged students.
                </p>
              </div>
              <div style={{ padding: "1%", overflow: "hidden" }}>
                <img
                  src={EnergisingLocalCommunity}
                  alt="Not Found"
                  style={{ width: "34%" }}
                />
              </div>
            </div>
            <div className="card-side-bar">
              <div
                onClick={() => setCarouselVisible(false)}
                className="close-button-box"
              >
                <img src={CloseIcon} className="close-button" />
              </div>
              <div className="right-info">
                <p className="social-card-type">SOCIAL</p>
                <div style={{ display: "flex" }}>
                  <p className="social-para-style cardno-style">01</p>
                  <p className="social-para-style total-no-style">/08</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* // second card */}
        <div className="parent-wrapper">
          <div className="parent-comp-2">
            <p className="social-main-heading">
              ENABLING <br /> DIGITAL TALENT AT SCALE
            </p>
            <div className="social-main-card">
              <div className="upper-content-div-2">
                <p className="social-main-content">
                  2.02 mn users on various learning platforms powered by Infosys
                  wingspan in 2021.
                  <br />
                  <br />
                  Lex, offers over 1,800 courses curated for easy consumption on
                  mobile devices with advanced telemetry, gamification and
                  certification features.
                  <br />
                  <br />
                  Over 2,40,000 of our employees use Lex.
                  <br />
                  <br />
                  1.2 mn students registered on the InfyTQ platform.
                  <br />
                  <br />
                  23 mn students and 1 mn teachers reached by the Infosys
                  Foundation USA, since inception in 2008
                </p>
              </div>
              <div style={{ padding: "2%", overflow: "hidden" }}>
                <img
                  src={DigitalTalent}
                  alt="Not Found"
                  style={{ width: "36%" }}
                />
              </div>
            </div>
            <div className="card-side-bar">
              <div
                onClick={() => setCarouselVisible(false)}
                className="close-button-box"
              >
                <img src={CloseIcon} className="close-button" />
              </div>
              <div className="right-info">
                <p className="social-card-type">SOCIAL</p>
                <div style={{ display: "flex" }}>
                  <p className="social-para-style cardno-style">02</p>
                  <p className="social-para-style total-no-style">/08</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* // Third Card */}
        <div className="parent-wrapper">
          <div className="parent-comp-1">
            <p className="social-main-heading">TECH FOR GOOD</p>
            <div className="social-main-card">
              <div className="upper-content-div-2">
                <p className="social-main-content">
                  80+million lives empowered through tech for good in
                  e-governance, healthcare and education.
                  <br />
                  <br />
                  US$69 million invested to support early stage start-up
                  companies to drive innovation in technology.
                  <br />
                  <br />
                  Tech solutions developed to support governments against
                  COVID-19.
                  <br />
                  <br />
                  US $1.1 million funding for The Aarohan Social Innovation
                  Awards recognize innovations in the social sector.
                </p>
              </div>
              <div style={{ padding: "2%", overflow: "hidden" }}>
                <img src={Tech} alt="Not Found" style={{ width: "36%" }} />
              </div>
            </div>
            <div className="card-side-bar">
              <div
                onClick={() => setCarouselVisible(false)}
                className="close-button-box"
              >
                <img src={CloseIcon} className="close-button" />
              </div>

              <div className="right-info">
                <p className="social-card-type">SOCIAL</p>
                <div style={{ display: "flex" }}>
                  <p className="social-para-style cardno-style">03</p>
                  <p className="social-para-style total-no-style">/08</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* //forth card */}
        <div className="parent-wrapper">
          <div className="parent-comp-1">
            <p className="social-main-heading">DIVERSITY AND INCLUSION</p>
            <div className="social-main-card">
              <div className="upper-content-div-2">
                <p className="social-main-content">
                  We have 39.1% women across the organization, 66% women in the
                  ESG committee of the board and 22% on the board.
                  <br />
                  <br />
                  Supporting unique initiatives (W2RT) to skill 10,000 Indian
                  women in emerging technologies.
                  <br />
                  <br />
                  Including people with disabilities and the LGBTQIA+ community.
                  <br />
                  <br />
                  144 nationalities are represented in our workforce.
                  <br />
                  <br />
                  Signatory to UN Women’s Empowerment Principles (WEP), UN Fair
                  & Equal (LGBTI Charter for Business)
                  <br />
                  <br />
                  Founder member of WEF Partnering for Racial Justice in
                  Business
                </p>
              </div>
              <div style={{ padding: "2%", overflow: "hidden" }}>
                <img src={Diversity} alt="Not Found" style={{ width: "34%" }} />
              </div>
            </div>
            <div className="card-side-bar">
              <div
                onClick={() => setCarouselVisible(false)}
                className="close-button-box"
              >
                <img src={CloseIcon} className="close-button" />
              </div>

              <div className="right-info">
                <p className="social-card-type">SOCIAL</p>
                <div style={{ display: "flex" }}>
                  <p className="social-para-style cardno-style">04</p>
                  <p className="social-para-style total-no-style">/08</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* // Fifth Card */}
        <div className="parent-wrapper">
          <div className="parent-comp-2">
            <p className="social-main-heading">
              ENERGIZING <br /> LOCAL COMMUNITIES
            </p>
            <div className="social-main-card">
              <div className="upper-content-div-2">
                <p className="social-main-content">
                  96.5% of our workforce is working from home and continuing to
                  deliver client value.
                  <br />
                  <br />
                  92% of employees hired are persons of local origin.
                  <br />
                  <br />
                  Development centers are set up in Indore, Nagpur, Mohali and
                  Hubballi in India, to promote mobility of talent outside Tier
                  1 cities.
                </p>
              </div>
              <div style={{ padding: "2%", overflow: "hidden" }}>
                <img
                  src={EnergisingLocalCommunity}
                  alt="Not Found"
                  style={{ width: "36%" }}
                />
              </div>
            </div>
            <div className="card-side-bar">
              <div
                onClick={() => setCarouselVisible(false)}
                className="close-button-box"
              >
                <img src={CloseIcon} className="close-button" />
              </div>

              <div className="right-info">
                <p className="social-card-type">SOCIAL</p>
                <div style={{ display: "flex" }}>
                  <p className="social-para-style cardno-style">05</p>
                  <p className="social-para-style total-no-style">/08</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Sixth Card */}
        {/* Seventh Card */}
        <div className="parent-wrapper">
          <div className="parent-comp-1">
            <p className="social-main-heading">SOCIAL INNOVATION</p>
            <div className="social-main-card">
              <div className="upper-content-div-2">
                <p className="social-main-content">
                  Social innovation for us is not the prerogative or privilege
                  rather is an integral part of the organizational structure.
                  <br />
                  <br />
                  During Covid -19 pandemic Crush Covid app and the
                  location-based services platform helped Indian government in
                  contact tracing, identifying hot spots and reporting the same.
                  <br />
                  <br />
                  We collaborated closely with the government of Karnataka in
                  developing and rolling out the Apthamitra mobile and web
                  application, contact centre solution and backend services in
                  association with a consortium of partners.
                </p>
              </div>
              <div style={{ padding: "2%", overflow: "hidden" }}>
                <img
                  src={Innovation}
                  alt="Not Found"
                  style={{ width: "36%" }}
                />
              </div>
            </div>
            <div className="card-side-bar">
              <div
                onClick={() => setCarouselVisible(false)}
                className="close-button-box"
              >
                <img src={CloseIcon} className="close-button" />
              </div>

              <div className="right-info">
                <p className="social-card-type">SOCIAL</p>
                <div style={{ display: "flex" }}>
                  <p className="social-para-style cardno-style">06</p>
                  <p className="social-para-style total-no-style">/08</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="parent-wrapper">
          <div className="parent-comp-2">
            <p className="social-main-heading">
              EMPLOYEE WELLNESS AND EXPERIENCE
            </p>
            <div className="social-main-card">
              <div className="upper-content-div-1">
                <p
                  className="social-main-content"
                  style={{ lineHeight: "15px" }}
                >
                  Best-in class employee experience – 71% overall employee
                  satisfaction score.
                  <br />
                  <br />
                  Promoting employee health and wellness through Health
                  Assessment and Lifestyle Enrichment program.
                  <br />
                  <br />
                  InfyMe, our self-service platform enables a seamless,
                  high-quality employee experience to access learning content
                  from anywhere, on any device, at any time.
                  <br />
                  <br />
                  Powered by future readiness, agile careers and always-on
                  learning, the Infosys career Mosaic is at the heart of our
                  Employee Value Proposition.
                  <br />
                  <br />
                  Our Health, Safety and Environmental Management System (HSEMS)
                  is certified to ISO 45001:2018 standard.
                </p>
              </div>
              <div style={{ padding: "1%", overflow: "hidden" }}>
                <img src={Wellness} alt="Not Found" style={{ width: "36%" }} />
              </div>
            </div>
            <div className="card-side-bar">
              <div
                onClick={() => setCarouselVisible(false)}
                className="close-button-box"
              >
                <img src={CloseIcon} className="close-button" />
              </div>
              <div className="right-info">
                <p className="social-card-type">SOCIAL</p>
                <div style={{ display: "flex" }}>
                  <p className="social-para-style cardno-style">07</p>
                  <p className="social-para-style total-no-style">/08</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="parent-wrapper">
          <div className="parent-comp-1">
            <p className="social-main-heading">ACHIEVEMENTS</p>
            <div className="social-main-card">
              <div className="upper-content-div-1">
                <p className="social-main-content">
                  Certified by the Great Place to Work® Institute as a Great
                  Place to Work® in India, for the period between March 2021 and
                  February 2022
                  <br />
                  <br />
                  Ranked among top 5 in 4 regions and 14 countries by Top
                  Employer Global 2021
                  <br />
                  <br />
                  Honored with a Glassdoor Employees’ Choice Award, recognizing
                  the Best Places to Work in 2021 in Canada
                  <br />
                  <br />
                  Certified as a Great Place to Work in the US in May 2020
                  <br />
                  <br />
                  Awarded Fortune Best Big Companies to Work For™ 2021 in the
                  USA
                  <br />
                  <br />
                  Top 10 Working Mother and AVTAR Best Company for Women in
                  India in 2021
                </p>
              </div>
              <div style={{ padding: "1%", overflow: "hidden" }}>
                <img
                  src={Achievements}
                  alt="Not Found"
                  style={{ width: "34%" }}
                />
              </div>
            </div>
            <div className="card-side-bar">
              <div
                onClick={() => setCarouselVisible(false)}
                className="close-button-box"
              >
                <img src={CloseIcon} className="close-button" />
              </div>
              <div className="right-info">
                <p className="social-card-type">SOCIAL</p>
                <div style={{ display: "flex" }}>
                  <p className="social-para-style cardno-style">08</p>
                  <p className="social-para-style total-no-style">/08</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default SocialCarousel;
