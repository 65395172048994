import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";

import DigitalTalent from "../Assets/icons-10.svg";
import Innovation from "../Assets/icons-08.svg";
import Tech from "../Assets/icons-11.svg";
import Wellness from "../Assets/icons-14.svg";
import Community from "../Assets/icons-13.svg";
import Diversity from "../Assets/icons-12.svg";
import Achievements from "../Assets/Achievements.svg";

import EnvironmentIcon from "../../../../common_assets/environment-icon 1.png";
import SocialIcon from "../../../../common_assets/social-icon 1.png";
import GovernanceIcon from "../../../../common_assets/governance-icon 1.png";
import ScrollTextImg from "../../../../common_assets/ScrollText.svg";
import ScrollhandImg from "../../../../common_assets/Hand_cursor.svg";

import "./socialgallery.css";
import ScrollContainer from "react-indiana-drag-scroll";

const useStyle = makeStyles({
  imageStyle: {
    width: "100%",
    height: "100%",
  },
});

let animationSpeed = 1;

function resumeAnimation() {
  setTimeout(() => {
    animationSpeed = 1;
  }, 500);
}

function StopAnimation() {
  animationSpeed = 0;
}

function InfiniteScroll() {
  var scrollPos = 10;
  var container = document.getElementsByClassName("container")[0];

  container.parentElement.scrollLeft = 1;

  container.parentElement.addEventListener(
    "scroll",
    function () {
      scrollPos =
        (container.parentElement.pageXOffset ||
          container.parentElement.scrollLeft) -
        (container.parentElement.clientLeft || 0);

      if (scrollPos >= 1395) {
        container.parentElement.scrollLeft = 1.8;
      }
      if (scrollPos <= 1) {
        container.parentElement.scrollLeft = 1395;
      }
    },
    false
  );

  function AnimateScroll() {
    container.parentElement.scrollLeft += animationSpeed;
  }
  AnimateScroll();
  setInterval(AnimateScroll, 20);
}

const SocialGallery = ({ setIsCarouselVisible, setCurrentIndex }) => {
  const classes = useStyle();

  useEffect(() => {
    InfiniteScroll();
  }, []);

  return (
    <>
      <ScrollContainer
        className="sliderGallery"
        nativeMobileScroll={false}
        hideScrollbars={true}
      >
        <div className="container">
          <div className="Social-Col Col1">
            <div
              className="socialcard"
              onClick={() => {
                setCurrentIndex(0);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Community} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingSocial">COMMUNITY</p>
          </div>

          <div className="Social-Col Col3">
            <div
              className="socialcard"
              onClick={() => {
                setCurrentIndex(1);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={DigitalTalent} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingSocial">
              ENABLING DIGITAL <br /> TALENT AT SCALE
            </p>
          </div>

          <div className="Social-Col Col2">
            <div
              className="socialcard"
              onClick={() => {
                setCurrentIndex(2);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Tech} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingSocial">TECH FOR GOOD</p>
          </div>

          <div className="Social-Col Col1">
            <div
              className="socialcard"
              style={{ width: "12.5vw" }}
              onClick={() => {
                setCurrentIndex(3);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Diversity} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingSocial">DIVERSITY AND INCLUSION</p>
          </div>

          <div className="Social-Col Col3">
            <div
              className="socialcard"
              onClick={() => {
                setCurrentIndex(4);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Community} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingSocial">
              ENERGIZING LOCAL <br /> COMMUNITIES
            </p>
          </div>

          <div className="Social-Col Col1">
            <div
              className="socialcard"
              onClick={() => {
                setCurrentIndex(5);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Innovation} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingSocial">SOCIAL INNOVATION</p>
          </div>

          <div className="Social-Col Col3">
            <div
              className="socialcard"
              onClick={() => {
                setCurrentIndex(6);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Wellness} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingSocial">
              EMPLOYEE WELLNESS <br /> AND EXPERIENCE
            </p>
          </div>
          <div className="Social-Col Col2">
            <div
              className="socialcard"
              onClick={() => {
                setCurrentIndex(7);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Achievements} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingSocial">ACHIEVEMENTS</p>
          </div>
          <div className="Social-Col Col1">
            <div
              className="socialcard"
              onClick={() => {
                setCurrentIndex(0);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Community} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingSocial">COMMUNITY</p>
          </div>

          <div className="Social-Col Col3">
            <div
              className="socialcard"
              onClick={() => {
                setCurrentIndex(1);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={DigitalTalent} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingSocial">
              ENABLING DIGITAL <br /> TALENT AT SCALE
            </p>
          </div>

          <div className="Social-Col Col2">
            <div
              className="socialcard"
              onClick={() => {
                setCurrentIndex(2);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Tech} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingSocial">TECH FOR GOOD</p>
          </div>

          <div className="Social-Col Col1">
            <div
              className="socialcard"
              style={{ width: "12.5vw" }}
              onClick={() => {
                setCurrentIndex(3);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Diversity} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingSocial">DIVERSITY AND INCLUSION</p>
          </div>

          <div className="Social-Col Col3">
            <div
              className="socialcard"
              onClick={() => {
                setCurrentIndex(4);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Community} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingSocial">
              ENERGIZING LOCAL <br /> COMMUNITIES
            </p>
          </div>

          <div className="Social-Col Col1">
            <div
              className="socialcard"
              onClick={() => {
                setCurrentIndex(5);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Innovation} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingSocial">SOCIAL INNOVATION</p>
          </div>

          <div className="Social-Col Col3">
            <div
              className="socialcard"
              onClick={() => {
                setCurrentIndex(6);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Wellness} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingSocial">
              EMPLOYEE WELLNESS <br /> AND EXPERIENCE
            </p>
          </div>

          <div className="Social-Col Col2">
            <div
              className="socialcard"
              onClick={() => {
                setCurrentIndex(7);
                setIsCarouselVisible(true);
              }}
            >
              <div className="InnerImgCard">
                <img src={Achievements} className={classes?.imageStyle} />
              </div>
            </div>
            <p className="CardHeadingSocial">ACHIEVEMENTS</p>
          </div>
        </div>
      </ScrollContainer>
      <div style={{ position: "absolute", right: "45%", bottom: "60px" }}>
        <div style={{ display: "flex" }}>
          <img src={ScrollhandImg} style={{ width: "18px", padding: "3px" }} />
          <img src={ScrollTextImg} />
        </div>
      </div>
      <div style={{ right: "0px", position: "absolute", bottom: "0px" }}>
        <div
          style={{ display: "flex", flexDirection: "row", maxHeight: "15vh" }}
        >
          <Link to="environment">
            <div>
              <img src={EnvironmentIcon} alt="Not Found" width="60%" />
            </div>
          </Link>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              <img src={SocialIcon} alt="Not Found" width="60%" />
            </div>
            <p
              style={{
                fontFamily: "TungstenFont",
                fontSize: "2rem",
                marginBottom: "0px",
                marginTop: "0px",
                marginLeft: "-6%",
                color: "#001738",
              }}
            >
              SOCIAL
            </p>
          </div>
          <Link to="governance">
            <div>
              <img src={GovernanceIcon} alt="Not Found" width="60%" />
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default SocialGallery;
