import React from "react";
import { Grid, makeStyles, Slide } from "@material-ui/core";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CloseIcon from "../../common_assets/close_btn.png";
import rightArrow from "../../common_assets/rightArrow.png";
import journeySoFarPic from "../../common_assets/journeySoFarPic.svg";
import { journeys } from "./constantData";
import ReadMoreModal from "./ReadMoreModal";

const useStyles = makeStyles((theme) => ({
  mainHeadingBox: {
    margin: "auto",
    marginTop: "-2.4%",
  },
  mainHeading: {
    fontFamily: "TungstenFont",
    // fontSize: "1em",
    fontWeight: "500",
    fontStyle: "normal",
    fontSize: "50px",
    lineHeight: "49px",
    textAlign: "center",
    marginTop: "2%",
  },
  wrapper: {
    margin: "0px 50px 32px 21% ",
    width: "68%",
    borderRight: "1px solid #000",
    paddingRight: "2%",
  },
  wrapper2: {
    margin: "0px 50px 32px 60px ",
    width: "239px",
    height: "130px",
    paddingRight: "2%",
  },
  title: {
    fontFamily: "TungstenFont",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "30px",
    lineHeight: "20px",
    paddingBottom: "2%",
    display: "flex",
    alignItems: "center",
    fontFeatureSettings: "sinf on",
    color: "#000000",
    opacity: "0.9",
  },
  subheading: {
    marginTop: "-19px",
    fontWeight: 700,
    fontSize: "15px",
    textAlign: "left",
    paddingBottom: "1%",
    color: "#003726",
    fontFamily: "RegularFont",
  },
  desc: {
    marginTop: "-19px",
    fontSize: "14px",
    textAlign: "left",
    color: "#003726",
    fontFamily: "RegularFont",
    fontWeight: "400",
  },
  viewMore: {
    fontSize: "13px",
    fontFamily: "SemiBoldFont",
    color: "#003726",
    marginTop: "-12px",
    marginBottom: "0px",
    textAlign: "left",
    width: "118px",
    background: "#AFDCF6",
    borderRadius: "10px",
    textDecoration: "none",
    padding: "5px 10px",
    fontWeight: "normal",
  },
  linkStyle: {
    textDecoration: "none",
    background: "inherit",
    cursor: "pointer",
  },
  combined: {
    fontFamily: "BoldFont",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "10px",
    color: "#003726",
    textAlign: "left",
  },
  pic: {
    marginLeft: "auto",
    verticalAlign: "bottom",
  },
  arrow: {
    height: "12px",
    width: "28px",
    marginTop: "2px",
  },
  parent: {
    marginTop: "-6.5%",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      height: "86vh",
    },
  },
  clienth: {
    fontFamily: "BoldFont",
    color: "#003726",
    marginTop: "0px",
    fontSize: "18px",
    textAlign: "left",
  },
  peopleh: {
    fontFamily: "BoldFont",
    color: "#003726",
    marginTop: "-8px",
    fontSize: "18px",
    textAlign: "left",
  },
  communityh: {
    fontFamily: "BoldFont",
    color: "#003726",
    marginTop: "-10px",
    fontSize: "18px",
    textAlign: "left",
  },
  environmenth: {
    fontFamily: "BoldFont",
    color: "#003726",
    marginTop: "-10px",
    fontSize: "18px",
    textAlign: "left",
  },
  clientp: {
    fontFamily: "RegularFont",
    color: "#003726",
    marginTop: "-10px",
    fontSize: "15px",
    textAlign: "left",
  },
  peoplep: {
    fontFamily: "RegularFont",
    color: "#003726",
    marginTop: "-10px",
    fontSize: "15px",
    textAlign: "left",
  },
  communityp: {
    fontFamily: "RegularFont",
    color: "#003726",
    marginTop: "-10px",
    fontSize: "15px",
    textAlign: "left",
  },
  environmentp: {
    fontFamily: "RegularFont",
    color: "#003726",
    marginTop: "-10px",
    fontSize: "15px",
    textAlign: "left",
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none",
  },
  bgDark: {
    background: "#DCDCDC",
  },
  uik: {
    [theme.breakpoints.down("md")]: {
      height: "100vh",
      width: "50vw",
    },
  },
}));

const JourneySoFar = () => {
  const classes = useStyles();
  const [disp, setDisp] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [entryData, setEntryData] = React.useState([]);

  const handleModelOpen = (id) => {
    let data = journeys?.find((i) => i.id == id);
    setEntryData(data);
    setOpenModal(true);
  };

  return (
    <div className={disp && classes.bgDark}>
      <div className={disp ? classes.hide : classes.mainHeadingBox}>
        <h6 className={classes.mainHeading}>Infosys ESG Journey So Far</h6>
        <div className={classes.parent}>
          <Grid container>
            {journeys?.map(({ id, title, subheading, desc }) => (
              <Grid item sm={6} xs={12} md={4} lg={3}>
                <div
                  className={id % 4 !== 0 ? classes.wrapper : classes.wrapper2}
                >
                  <h2 variant="h6" className={classes.title}>
                    {title}
                  </h2>
                  <div className="combined">
                    <p variant="p" className={classes.subheading}>
                      {subheading}
                    </p>
                    <p className={classes.desc}>{desc}</p>
                  </div>
                  <a
                    onClick={() => handleModelOpen(id)}
                    className={classes.linkStyle}
                  >
                    {" "}
                    <p className={classes?.viewMore}>
                      Read More{" "}
                      <img src={rightArrow} className={classes.arrow} alt="" />
                    </p>{" "}
                  </a>
                </div>
              </Grid>
            ))}

            <img src={journeySoFarPic} className={classes.pic} alt="" />
          </Grid>
        </div>
      </div>
      <ReadMoreModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        entryData={entryData}
      />
    </div>
  );
};

export default JourneySoFar;
