import React from "react";
import ReactPlayer from "react-player";
import { Modal } from "@material-ui/core";
import CloseIcon from "../../common_assets/download.png";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles({
  modalChildDiv: {
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    paddingRight: "1%",
    marginTop: "10vh",
    marginLeft: "10vh",
    outline: "none",
  },
  closeButtton: {
    cursor: "pointer",
    marginLeft: "2%",
  },
});
const VideoModal = ({ openModal, setOpenModal }) => {
  const classes = useStyles();
  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      style={{ border: "none" }}
      overlayStyle={{ backgroundColor: "transparent" }}
    >
      <div className={classes?.modalChildDiv}>
        <ReactPlayer
          url="https://www.youtube.com/watch?v=EDJZFlPhD_I"
          width="100%"
          height="calc(100vh - 100px)"
          style={{ border: "2px solid white" }}
          controls="true"
          light="http://i3.ytimg.com/vi/EDJZFlPhD_I/maxresdefault.jpg"
          playing="true"
        />
        <div
          onClick={() => setOpenModal(false)}
          className={classes?.closeButtton}
        >
          <img style={{ maxWidth: "90%" }} src={CloseIcon} />
        </div>
      </div>
    </Modal>
  );
};

export default VideoModal;
