import React, { useState, useEffect } from "react";
import { makeStyles, Container, Button, Grid } from "@material-ui/core";
import EnvironmentImg1 from "../../common_assets/environment-icon 1.svg";
import GovernanceImg1 from "../../common_assets/governance-icon 1.svg";
import SocialImg1 from "../../common_assets/social-icon 1.svg";
import { ImgCard1, ImgCard2, ImgCard3 } from "./prioritySection.style";
import EnvironmentImg from "../../common_assets/environment-resize-image.png";
import SocialImg from "../../common_assets/social-icon-3-edit.png";
import GovernanceImg from "../../common_assets/governance-icon-3-edit.png";
import WaveLoader from "./Loader/index";
import Footer from "../Footer";
import {
  MainWrapper,
  HeadingBox,
  MainCard,
  Heading1,
  Heading3,
  Paragraph1,
} from "./prioritySection.style.js";
import { Heading2 } from "./prioritySection.style";

import { createRipples } from "react-ripples";

const useStyle = makeStyles({
  row: {
    margin: "0 -5px",
    ["@media only screen and (max-width: 598px)"]: {
      display: "table",
      clear: "both",
    },
  },

  column: {
    float: "left",
    width: "25%",
    padding: "0 10px",
    ["@media only screen and (max-width: 598px)"]: {
      width: "100%",
      display: "block",
      marginBottom: "20px",
    },
  },

  imageBox: {
    textAlign: "left",
  },

  image: {
    verticalAlign: "bottom",
  },
  mainHeadingBox: {
    margin: "auto",
    marginTop: "-1%",
  },
  mainHeading: {
    fontFamily: "TungstenFont",
    fontSize: "5em",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "70px",
    lineHeight: "69px",
    TextAlign: "center",
    marginTop: "0%",
    ["@media only screen and (max-width: 545px)"]: {
      fontSize: "4.6em",
    },
    ["@media only screen and (max-width: 450px)"]: {
      fontSize: "3.8em",
    },
    ["@media only screen and (max-width: 370px)"]: {
      fontSize: "3em",
    },
    ["@media only screen and (max-width: 290px)"]: {
      fontSize: "2.5em",
    },
  },
  heading1: {
    color: "#003726",
    fontFamily: "SemiBoldFont",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "35px",
    lineHeight: "15px",
    ["@media only screen and (max-width: 860px)"]: {
      fontSize: "1.6em",
    },
    ["@media only screen and (max-width: 450px)"]: {
      fontSize: "1.3em",
    },
    ["@media only screen and (max-width: 290px)"]: {
      fontSize: "0.9em",
    },
  },
  paragraph1: {
    color: "#003726",
    fontFamily: "RegularFont",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "25px",
    ["@media only screen and (max-width: 860px)"]: {
      fontSize: "16px",
    },
    ["@media only screen and (max-width: 390px)"]: {
      fontSize: "13px",
    },
    ["@media only screen and (max-width: 290px)"]: {
      fontSize: "12px",
    },
  },
  heading2: {
    color: "#001738",
    fontFamily: "SemiBoldFont",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "35px",
    lineHeight: "0px",
    ["@media only screen and (max-width: 860px)"]: {
      fontSize: "1.6em",
    },
    ["@media only screen and (max-width: 450px)"]: {
      fontSize: "1.3em",
    },
    ["@media only screen and (max-width: 290px)"]: {
      fontSize: "0.9em",
    },
  },
  paragraph2: {
    color: "#001738",
    fontFamily: "RegularFont",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "25px",
    ["@media only screen and (max-width: 860px)"]: {
      fontSize: "16px",
    },
    ["@media only screen and (max-width: 390px)"]: {
      fontSize: "13px",
    },
    ["@media only screen and (max-width: 280px)"]: {
      fontSize: "12px",
    },
  },
  heading3: {
    color: "#381460",
    fontFamily: "SemiBoldFont",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "35px",
    lineHeight: "0px",
    ["@media only screen and (max-width: 860px)"]: {
      fontSize: "1.6em",
    },
    ["@media only screen and (max-width: 450px)"]: {
      fontSize: "1.3em",
    },
    ["@media only screen and (max-width: 290px)"]: {
      fontSize: "0.9em",
    },
  },
  paragraph3: {
    color: "#381460",
    fontFamily: "RegularFont",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "25px",
    ["@media only screen and (max-width: 860px)"]: {
      fontSize: "16px",
    },
    ["@media only screen and (max-width: 390px)"]: {
      fontSize: "13px",
    },
    ["@media only screen and (max-width: 290px)"]: {
      fontSize: "12px",
    },
  },
  footer: {
    position: "absolute",
    right: "10px",
    bottom: "0px",
  },
});

const EnvironmentRipple = createRipples({
  color: "#50AF91",
  during: 3200,
});

const SocialRipple = createRipples({
  color: "#027FBD",
  during: 3200,
});

const GovernanceRipple = createRipples({
  color: "#8D2A90",
  during: 3200,
});

const PrioritySection = ({
  isLoading,
  setIsLoading,
  loadingType,
  setLoadingType,
}) => {
  const classes = useStyle();

  return (
    <>
      {isLoading ? <WaveLoader loadingType={loadingType} /> : ""}
      <div style={{ height: "86vh" }}>
        <div className={classes.mainHeadingBox}>
          <h1 className={classes.mainHeading}>Our ESG Priorities</h1>
        </div>
        <MainWrapper>
          <MainCard>
            <div className={classes?.imageBox}>
              <img
                src={EnvironmentImg1}
                width="22%"
                alt="Not found"
                className={classes?.image}
              />
            </div>
            <EnvironmentRipple>
              <ImgCard1
                onClick={() => {
                  setIsLoading(true);
                  setLoadingType("environment");
                }}
              >
                <HeadingBox>
                  <h1 className={classes?.heading1}>Environment</h1>
                  <p className={classes?.paragraph1}>
                    Serve the preservation of our planet by shaping and sharing
                    our technology solutions
                  </p>
                </HeadingBox>
                <div>
                  <img
                    src={EnvironmentImg}
                    alt="Not Found"
                    width="100%"
                    style={{ verticalAlign: "bottom" }}
                  />
                </div>
              </ImgCard1>
            </EnvironmentRipple>
          </MainCard>
          <MainCard>
            <div className={classes?.imageBox}>
              <img
                src={SocialImg1}
                alt="Not found"
                width="22%"
                className={classes?.image}
              />
            </div>
            <SocialRipple>
              <ImgCard2
                onClick={() => {
                  setIsLoading(true);
                  setLoadingType("social");
                }}
              >
                <HeadingBox>
                  <h2 className={classes.heading2}>Social</h2>
                  <p className={classes?.paragraph2}>
                    Serve the development of people by shaping a future with
                    meaningful opportunities for all
                  </p>
                </HeadingBox>
                <div>
                  <img
                    src={SocialImg}
                    alt="Not Found"
                    width="100%"
                    style={{ verticalAlign: "bottom" }}
                  />
                </div>
              </ImgCard2>
            </SocialRipple>
          </MainCard>
          <MainCard>
            <div className={classes?.imageBox}>
              <img
                src={GovernanceImg1}
                alt="Not found"
                width="22%"
                className={classes?.image}
              />
            </div>
            <GovernanceRipple>
              <ImgCard3
                onClick={() => {
                  setIsLoading(true);
                  setLoadingType("governance");
                }}
              >
                <HeadingBox>
                  <h2 className={classes?.heading3}>Governance</h2>
                  <p className={classes?.paragraph3}>
                    Serve the interests of all our stakeholders by leading
                    through our core values
                  </p>
                </HeadingBox>
                <div>
                  <img
                    src={GovernanceImg}
                    alt="Not Found"
                    width="100%"
                    style={{ verticalAlign: "bottom" }}
                  />
                </div>
              </ImgCard3>
            </GovernanceRipple>
          </MainCard>
        </MainWrapper>
      </div>
    </>
  );
};
export default PrioritySection;
